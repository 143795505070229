// Generated by ReScript, PLEASE EDIT WITH CARE


function defaultLocale_achBankDebitTerms(_str) {
  return "Your ACH Debit Authorization will be set up now, but we'll confirm the amount and let you know before future payments are taken.";
}

function defaultLocale_sepaDebitTerms(_str) {
  return "Your SEPA Debit Authorization will be set up now, but we'll confirm the amount and let you know before future payments are taken.";
}

function defaultLocale_cardTerms(str) {
  return "By providing your card information, you allow " + str + " to charge your card for future payments in accordance with their terms.";
}

function defaultLocale_surchargeMsgAmount(str) {
  return "A surcharge amount of " + str + " will be applied for this transaction";
}

function defaultLocale_surchargeMsgAmountForCard(str) {
  return "A surcharge amount of upto " + str + " will be applied for this transaction";
}

var defaultLocale = {
  locale: "en",
  cardNumberLabel: "Card Number",
  localeDirection: "ltr",
  inValidCardErrorText: "Card number is invalid.",
  inCompleteCVCErrorText: "Your card's security code is incomplete.",
  inCompleteExpiryErrorText: "Your card's expiration date is incomplete.",
  pastExpiryErrorText: "Your card's expiration date is invalid",
  poweredBy: "Powered By Hyperswitch",
  validThruText: "Expiry",
  sortCodeText: "Sort Code",
  cvcTextLabel: "CVC",
  emailLabel: "Email",
  accountNumberText: "Account Number",
  fullNameLabel: "Full name",
  line1Label: "Address line 1",
  line1Placeholder: "Street address",
  line2Label: "Address line 2",
  line2Placeholder: "Apt., unit number, etc (optional)",
  cityLabel: "City",
  postalCodeLabel: "Postal Code",
  stateLabel: "State",
  fullNamePlaceholder: "First and last name",
  countryLabel: "Country",
  currencyLabel: "Currency",
  bankLabel: "Select Bank",
  redirectText: "After submitting your order, you will be redirected to securely complete your purchase.",
  bankDetailsText: "After submitting these details, you will get bank account information to make payment. Please make sure to take a note of it.",
  orPayUsing: "Or pay using",
  addNewCard: "Add credit/debit card",
  useExisitingSavedCards: "Use saved debit/credit cards",
  saveCardDetails: "Save card details",
  addBankAccount: "Add bank account",
  achBankDebitTerms: defaultLocale_achBankDebitTerms,
  sepaDebitTerms: defaultLocale_sepaDebitTerms,
  becsDebitTerms: "Your BECS Debit Authorization will be set up now, but we'll confirm the amount and let you know before future payments are taken.",
  cardTerms: defaultLocale_cardTerms,
  payNowButton: "Pay Now",
  cardNumberEmptyText: "Card Number cannot be empty",
  cardExpiryDateEmptyText: "Card expiry date cannot be empty",
  cvcNumberEmptyText: "CVC Number cannot be empty",
  enterFieldsText: "Please enter all fields",
  enterValidDetailsText: "Please enter valid details",
  card: "Card",
  surchargeMsgAmount: defaultLocale_surchargeMsgAmount,
  surchargeMsgAmountForCard: defaultLocale_surchargeMsgAmountForCard,
  billingNameLabel: "Billing name",
  billingNamePlaceholder: "First and last name",
  cardHolderName: "Card Holder Name"
};

var localeStrings = [
  {
    locale: "en",
    cardNumberLabel: "Card Number",
    localeDirection: "ltr",
    inValidCardErrorText: "Card number is invalid.",
    inCompleteCVCErrorText: "Your card's security code is incomplete.",
    inCompleteExpiryErrorText: "Your card's expiration date is incomplete.",
    pastExpiryErrorText: "Your card's expiration year is in the past.",
    poweredBy: "Powered By Hyperswitch",
    validThruText: "Expiry",
    sortCodeText: "Sort Code",
    cvcTextLabel: "CVC",
    emailLabel: "Email",
    accountNumberText: "Account Number",
    fullNameLabel: "Full name",
    line1Label: "Address line 1",
    line1Placeholder: "Street address",
    line2Label: "Address line 2",
    line2Placeholder: "Apt., unit number, etc (optional)",
    cityLabel: "City",
    postalCodeLabel: "Postal Code",
    stateLabel: "State",
    fullNamePlaceholder: "First and last name",
    countryLabel: "Country",
    currencyLabel: "Currency",
    bankLabel: "Select Bank",
    redirectText: "After submitting your order, you will be redirected to securely complete your purchase.",
    bankDetailsText: "After submitting these details, you will get bank account information to make payment. Please make sure to take a note of it.",
    orPayUsing: "Or pay using",
    addNewCard: "Add credit/debit card",
    useExisitingSavedCards: "Use saved debit/credit cards",
    saveCardDetails: "Save card details",
    addBankAccount: "Add bank account",
    achBankDebitTerms: (function (str) {
        return "Your ACH Debit Authorization will be set up now, but we'll confirm the amount and let you know before future payments are taken.";
      }),
    sepaDebitTerms: (function (str) {
        return "By providing your payment information and confirming this payment, you authorise (A) " + str + " and Hyperswitch, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.";
      }),
    becsDebitTerms: "By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the Direct Debit Request service agreement and authorise Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Hyperswitch”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of Hyperswitch Payment Widget (the \"Merchant\") for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.",
    cardTerms: (function (str) {
        return "By providing your card information, you allow " + str + " to charge your card for future payments in accordance with their terms.";
      }),
    payNowButton: "Pay Now",
    cardNumberEmptyText: "Card Number cannot be empty",
    cardExpiryDateEmptyText: "Card expiry date cannot be empty",
    cvcNumberEmptyText: "CVC Number cannot be empty",
    enterFieldsText: "Please enter all fields",
    enterValidDetailsText: "Please enter valid details",
    card: "Card",
    surchargeMsgAmount: (function (str) {
        return "A surcharge amount of " + str + " will be applied for this transaction";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "A surcharge amount of upto " + str + " will be applied for this transaction";
      }),
    billingNameLabel: "Billing name",
    billingNamePlaceholder: "First and last name",
    cardHolderName: "Card Holder Name"
  },
  {
    locale: "he",
    cardNumberLabel: "מספר כרטיס",
    localeDirection: "rtl",
    inValidCardErrorText: "אינו תקין",
    inCompleteCVCErrorText: "קוד האבטחה של הכרטיס שלך אינו שלם",
    inCompleteExpiryErrorText: "פרטי תוקף של הכרטיס שלך אינם מלאים",
    pastExpiryErrorText: "שנת תוקף של הכרטיס שלך עברה.",
    poweredBy: "מופעל על ידי Hyperswitch",
    validThruText: "תוקף",
    sortCodeText: "קוד מיון",
    cvcTextLabel: "קוד בגב הכרטיס",
    emailLabel: "אימייל",
    accountNumberText: "מספר חשבון",
    fullNameLabel: "שם מלא",
    line1Label: "כתובת - שורה 1",
    line1Placeholder: "כתובת רחוב",
    line2Label: "כתובת - שורה 2",
    line2Placeholder: "דירה, יחידה, וכדומה (אופציונלי)",
    cityLabel: "עיר",
    postalCodeLabel: "מיקוד",
    stateLabel: "מדינה",
    fullNamePlaceholder: "שם פרטי ושם משפחה",
    countryLabel: "מדינה",
    currencyLabel: "מטבע",
    bankLabel: "בחר בנק",
    redirectText: "לאחר שליחת ההזמנה שלך, תועבר להשלמת הרכישה באופן מאובטח.",
    bankDetailsText: "לאחר הגשת פרטים אלה, תקבל את פרטי החשבון הבנקאי לביצוע התשלום. נא לוודא לשמור את הפרטים.",
    orPayUsing: "או לשלם באמצעות",
    addNewCard: "הוספת כרטיס אשראי/כרטיס חיוב (דביט)",
    useExisitingSavedCards: "שימוש בכרטיסי חיוב/ אשראי שמורים",
    saveCardDetails: "שמירת פרטי הכרטיס",
    addBankAccount: "הוסף חשבון בנק",
    achBankDebitTerms: (function (str) {
        return "על ידי הזנת מספר החשבון שלך ואישור תשלום זה, אתה מאשר את " + str + " ואת Hyperswitch, ספק השירותים התשלום שלנו, לשלוח הוראות לבנק שלך לחייב את החשבון שלך ולהפחית את החשבון שלך על פי ההוראות האלה. אתה זכאי לקבל החזר מהבנק שלך על פי תנאי ההסכם שלך עם הבנק. ההחזר יכול להידרש תוך 8 שבועות מהתאריך שבו הוא הופחת מחשבונך.";
      }),
    sepaDebitTerms: (function (str) {
        return "על ידי הזנת מידע התשלום שלך ואישור תשלום זה, אתה מאשר (א) את " + str + " ואת Hyperswitch, ספק השירותים התשלום שלנו ו/או PPRO, ספק השירותים המקומי שלו, לשלוח הוראות לבנק שלך לחייב את החשבון שלך ו(ב) את הבנק שלך לחייב את החשבון שלך על פי ההוראות האלה. כחלק מזכויותיך, אתה זכאי לקבל החזר מהבנק שלך על פי תנאי ההסכם שלך עם הבנק. ההחזר יכול להידרש תוך 8 שבועות מהתאריך שבו הוא הופחת מחשבונך. הזכויות שלך מוסברות בהצהרה שאתה יכול לקבל מהבנק שלך. אתה מסכים לקבל הודעות להוראות חיוב עתידיות עד 2 ימים לפני החיוב.";
      }),
    becsDebitTerms: "על ידי הזנת פרטי החשבון הבנקאי שלך ואישור תשלום זה, אתה מסכים לבקשת החיוב הישיר ולהסכם תשלום בקשת החיוב הישיר ומאשר את Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 מספר זיהוי משתמש בקשת החיוב הישיר 507156 (Hyperswitch) לחייב את החשבון שלך דרך מערכת BECS (Bulk Electronic Clearing System) בשם Hyperswitch Payment Widget (הסוחר) עבור סכומים הודעו לך בנפרד על ידי הסוחר. אתה מאשר שאתה בעל החשבון או חותם מורשה בחשבון שצוין למעלה.",
    cardTerms: (function (str) {
        return "על ידי הזנת פרטי הכרטיס שלך, אתה מאפשר ל" + str + " לחייב את הכרטיס שלך עבור תשלומים עתידיים על פי תנאיהם.";
      }),
    payNowButton: "שלם עכשיו",
    cardNumberEmptyText: "מספר הכרטיס אינו יכול להיות ריק",
    cardExpiryDateEmptyText: "תוקף של הכרטיס אינו יכול להיות ריק",
    cvcNumberEmptyText: "פרטי קוד ה-CVC אינם יכולים להיות ריקים",
    enterFieldsText: "יש להזין את כל השדות",
    enterValidDetailsText: "יש להזין פרטים תקינים",
    card: "כרטיס",
    surchargeMsgAmount: (function (str) {
        return "סכום היטל של " + str + " יוחל עבור עסקה זו";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "סכום היטל של עד " + str + " יחול עבור עסקה זו";
      }),
    billingNameLabel: "שם החיוב",
    billingNamePlaceholder: "שם פרטי ושם משפחה",
    cardHolderName: "שם בעל הכרטיס"
  },
  {
    locale: "fr",
    cardNumberLabel: "Numéro de carte",
    localeDirection: "ltr",
    inValidCardErrorText: "Le numéro de carte n'est pas valide.",
    inCompleteCVCErrorText: "Le code de sécurité de votre carte est incomplet.",
    inCompleteExpiryErrorText: "La date d'expiration de votre carte est incomplète.",
    pastExpiryErrorText: "L'année d'expiration de votre carte est passée.",
    poweredBy: "Propulsé par Hyperswitch",
    validThruText: "Expiration",
    sortCodeText: "Code de tri",
    cvcTextLabel: "Code CVC",
    emailLabel: "E-mail",
    accountNumberText: "Numéro de compte",
    fullNameLabel: "Nom complet",
    line1Label: "Adresse - Ligne 1",
    line1Placeholder: "Adresse de rue",
    line2Label: "Adresse - Ligne 2",
    line2Placeholder: "Appartement, numéro d'unité, etc (facultatif)",
    cityLabel: "Ville",
    postalCodeLabel: "Code postal",
    stateLabel: "État",
    fullNamePlaceholder: "Prénom et nom de famille",
    countryLabel: "Pays",
    currencyLabel: "Devise",
    bankLabel: "Sélectionnez une banque",
    redirectText: "Après avoir soumis votre commande, vous serez redirigé(e) pour compléter votre achat en toute sécurité.",
    bankDetailsText: "Après avoir soumis ces détails, vous obtiendrez les informations de compte bancaire pour effectuer le paiement. Assurez-vous de les noter.",
    orPayUsing: "Ou payer avec",
    addNewCard: "Ajouter une carte de crédit/débit",
    useExisitingSavedCards: "Utiliser les cartes de débit/crédit enregistrées",
    saveCardDetails: "Enregistrer les détails de la carte",
    addBankAccount: "Ajouter un compte bancaire",
    achBankDebitTerms: (function (str) {
        return "En fournissant votre numéro de compte et en confirmant ce paiement, vous autorisez " + str + " et Hyperswitch, notre fournisseur de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et à débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les modalités de votre accord avec votre banque. Une demande de remboursement doit être effectuée dans les 8 semaines à compter de la date à laquelle votre compte a été débité.";
      }),
    sepaDebitTerms: (function (str) {
        return "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) " + str + " et Hyperswitch, notre fournisseur de services de paiement, et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous avez droit à un remboursement de votre banque selon les modalités de votre accord avec votre banque. Une demande de remboursement doit être effectuée dans les 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications pour les débits futurs jusqu'à 2 jours avant leur occurrence.";
      }),
    becsDebitTerms: "En fournissant les détails de votre compte bancaire et en confirmant ce paiement, vous acceptez cette demande de prélèvement direct et l'accord de service de demande de prélèvement direct, et autorisez Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 numéro d'identification de l'utilisateur de prélèvement direct 507156 (“Hyperswitch”) à débiter votre compte via le système de compensation électronique en masse (BECS) au nom de Hyperswitch Payment Widget (le \"Marchand\") pour les montants communiqués séparément par le Marchand. Vous certifiez que vous êtes soit titulaire d'un compte, soit signataire autorisé sur le compte indiqué ci-dessus.",
    cardTerms: (function (str) {
        return "En fournissant les informations de votre carte, vous autorisez " + str + " à débiter votre carte pour les paiements futurs conformément à leurs conditions.";
      }),
    payNowButton: "Payer maintenant",
    cardNumberEmptyText: "Le numéro de carte ne peut pas être vide",
    cardExpiryDateEmptyText: "La date d'expiration de la carte ne peut pas être vide",
    cvcNumberEmptyText: "Le numéro CVC ne peut pas être vide",
    enterFieldsText: "Veuillez saisir tous les champs",
    enterValidDetailsText: "Veuillez saisir des informations valides",
    card: "Carte",
    surchargeMsgAmount: (function (str) {
        return "Un montant supplémentaire d'" + str + " sera appliqué pour cette transaction";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "Un montant supplémentaire allant jusqu'à " + str + " sera appliqué pour cette transaction.";
      }),
    billingNameLabel: "Nom de facturation",
    billingNamePlaceholder: "Prénom et nom de famille",
    cardHolderName: "Nom du titulaire"
  },
  {
    locale: "en-GB",
    cardNumberLabel: "Card Number",
    localeDirection: "ltr",
    inValidCardErrorText: "Card number is invalid.",
    inCompleteCVCErrorText: "Your card's security code is incomplete.",
    inCompleteExpiryErrorText: "Your card's expiration date is incomplete.",
    pastExpiryErrorText: "Your card's expiration year is in the past.",
    poweredBy: "Powered By Hyperswitch",
    validThruText: "Expiry",
    sortCodeText: "Sort Code",
    cvcTextLabel: "CVC",
    emailLabel: "Email",
    accountNumberText: "Account Number",
    fullNameLabel: "Full name",
    line1Label: "Address line 1",
    line1Placeholder: "Street address",
    line2Label: "Address line 2",
    line2Placeholder: "Apt., unit number, etc (optional)",
    cityLabel: "City",
    postalCodeLabel: "Postal Code",
    stateLabel: "State",
    fullNamePlaceholder: "First and last name",
    countryLabel: "Country",
    currencyLabel: "Currency",
    bankLabel: "Select Bank",
    redirectText: "After submitting your order, you will be redirected to securely complete your purchase.",
    bankDetailsText: "After submitting these details, you will get bank account information to make payment. Please make sure to take a note of it.",
    orPayUsing: "Or pay using",
    addNewCard: "Add credit/debit card",
    useExisitingSavedCards: "Use saved debit/credit cards",
    saveCardDetails: "Save card details",
    addBankAccount: "Add bank account",
    achBankDebitTerms: (function (str) {
        return "Your ACH Debit Authorization will be set up now, but we'll confirm the amount and let you know before future payments are taken.";
      }),
    sepaDebitTerms: (function (str) {
        return "By providing your payment information and confirming this payment, you authorise (A) " + str + " and Hyperswitch, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.";
      }),
    becsDebitTerms: "By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the Direct Debit Request service agreement and authorise Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Hyperswitch”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of Hyperswitch Payment Widget (the \"Merchant\") for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.",
    cardTerms: (function (str) {
        return "By providing your card information, you allow " + str + " to charge your card for future payments in accordance with their terms.";
      }),
    payNowButton: "Pay Now",
    cardNumberEmptyText: "Card Number cannot be empty",
    cardExpiryDateEmptyText: "Card expiry date cannot be empty",
    cvcNumberEmptyText: "CVC Number cannot be empty",
    enterFieldsText: "Please enter all fields",
    enterValidDetailsText: "Please enter valid details",
    card: "Card",
    surchargeMsgAmount: (function (str) {
        return "A surcharge amount of " + str + " will be applied for this transaction";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "A surcharge amount of upto " + str + " will be applied for this transaction";
      }),
    billingNameLabel: "Billing name",
    billingNamePlaceholder: "First and last name",
    cardHolderName: "Card Holder Name"
  },
  {
    locale: "ar",
    cardNumberLabel: "رقم البطاقة",
    localeDirection: "rtl",
    inValidCardErrorText: "رقم البطاقة غير صالحة",
    inCompleteCVCErrorText: "رمز أمان بطاقتك غير مكتمل",
    inCompleteExpiryErrorText: "تاريخ انتهاء صلاحية بطاقتك غير مكتمل",
    pastExpiryErrorText: "انقضت سنة انتهاء صلاحية بطاقتك",
    poweredBy: "مدعوم من هيبيرسويتش",
    validThruText: "صالحة من خلال",
    sortCodeText: "الكود البنكى",
    cvcTextLabel: "رمز الحماية",
    emailLabel: "البريد الإلكتروني",
    accountNumberText: "رقم حساب",
    fullNameLabel: "الاسم الكامل",
    line1Label: "العنوان سطر 1",
    line1Placeholder: ".عنوان الشارع",
    line2Label: "سطر العنوان 2",
    line2Placeholder: "مناسب ، رقم الوحدة ، إلخ (اختياري)",
    cityLabel: "مدينة",
    postalCodeLabel: "رمز بريدي",
    stateLabel: "ولاية",
    fullNamePlaceholder: "الاسم الأول والاسم الأخير",
    countryLabel: "دولة",
    currencyLabel: "عملة",
    bankLabel: "حدد البنك",
    redirectText: "بعد تقديم طلبك ، ستتم إعادة توجيهك لإكمال عملية الشراء بشكل آمن.",
    bankDetailsText: "بعد إرسال هذه التفاصيل ، ستحصل على معلومات الحساب المصرفي لإجراء الدفع. يُرجى التأكد من تدوين ذلك.",
    orPayUsing: "أو الدفع باستخدام",
    addNewCard: "أضف بطاقة جديدة",
    useExisitingSavedCards: "استخدم البطاقات المحفوظة الموجودة",
    saveCardDetails: "حفظ تفاصيل البطاقة",
    addBankAccount: "إضافة حساب مصرفي",
    achBankDebitTerms: (function (str) {
        return "من خلال تقديم رقم حسابك وتأكيد هذا الدفع ، فإنك تفوض " + str + " و Hyperswitch ، مزود خدمة الدفع ، لإرسال تعليمات إلى البنك الذي تتعامل معه للخصم من حسابك والبنك الخاص بك للخصم من حسابك وفقًا لهذه التعليمات. يحق لك استرداد الأموال من البنك الذي تتعامل معه بموجب شروط وأحكام اتفاقيتك مع البنك الذي تتعامل معه. يجب المطالبة باسترداد الأموال في غضون 8 أسابيع بدءًا من تاريخ الخصم من حسابك.";
      }),
    sepaDebitTerms: (function (str) {
        return "من خلال تقديم معلومات الدفع الخاصة بك وتأكيد هذا الدفع ، فإنك تفوض (أ) " + str + " و Hyperswitch ، موفر خدمة الدفع لدينا و / أو PPRO ، مزود الخدمة المحلي ، لإرسال تعليمات إلى البنك الذي تتعامل معه للخصم من حسابك و (ب) البنك الذي تتعامل معه للخصم من حسابك وفقًا لتلك التعليمات. كجزء من حقوقك ، يحق لك استرداد الأموال من البنك الذي تتعامل معه بموجب شروط وأحكام اتفاقيتك مع البنك الذي تتعامل معه. يجب المطالبة باسترداد الأموال في غضون 8 أسابيع بدءًا من تاريخ الخصم من حسابك. يتم توضيح حقوقك في بيان يمكنك الحصول عليه من البنك الذي تتعامل معه. أنت توافق على تلقي إشعارات بالخصم المستقبلي لمدة تصل إلى يومين قبل حدوثها.";
      }),
    becsDebitTerms: "من خلال تقديم تفاصيل حسابك المصرفي وتأكيد هذه الدفعة ، فإنك توافق على طلب الخصم المباشر هذا واتفاقية خدمة طلب الخصم المباشر وتفوض Hyperswitch Payments Australia Pty Ltd ACN 160180343 رقم معرف مستخدم الخصم المباشر 507156 (\"Hyperswitch\") للخصم من حسابك حساب من خلال نظام المقاصة الإلكترونية المجمعة (BECS) نيابة عن Hyperswitch Payment Widget (\"التاجر\") لأي مبالغ يرسلها التاجر لك بشكل منفصل. أنت تقر بأنك إما صاحب حساب أو مفوض بالتوقيع على الحساب المذكور أعلاه.",
    cardTerms: (function (str) {
        return "من خلال تقديم معلومات بطاقتك ، فإنك تسمح لـ " + str + " بشحن بطاقتك للمدفوعات المستقبلية وفقًا لشروطها.";
      }),
    payNowButton: "ادفع الآن",
    cardNumberEmptyText: "لا يمكن أن يكون رقم البطاقة فارغاً",
    cardExpiryDateEmptyText: "لا يمكن أن يكون تاريخ انتهاء البطاقة فارغاً",
    cvcNumberEmptyText: "لا يمكن أن يكون رقم التحقق من البطاقة (CVC) فارغًا",
    enterFieldsText: "الرجاء إدخال كافة الحقول",
    enterValidDetailsText: "الرجاء إدخال تفاصيل صالحة",
    card: "بطاقة",
    surchargeMsgAmount: (function (str) {
        return "سيتم تطبيق مبلغ إضافي من " + str + " على هذه المعاملة";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "سيتم تطبيق مبلغ إضافي يصل إلى " + str + " على هذه المعاملة";
      }),
    billingNameLabel: "اسم الفواتير",
    billingNamePlaceholder: "الاسم الأول والاسم الأخير",
    cardHolderName: "إسم صاحب البطاقة"
  },
  {
    locale: "ja",
    cardNumberLabel: "カード番号",
    localeDirection: "ltr",
    inValidCardErrorText: "カード番号が無効です",
    inCompleteCVCErrorText: "カードのセキュリティ コードが不完全です",
    inCompleteExpiryErrorText: "カードの有効期限が不完全です",
    pastExpiryErrorText: "カードの有効期限が過ぎています",
    poweredBy: "ハイパースイッチ搭載",
    validThruText: "を通じて有効",
    sortCodeText: "ソートコード",
    cvcTextLabel: "セキュリティコード",
    emailLabel: "Eメール",
    accountNumberText: "口座番号",
    fullNameLabel: "フルネーム",
    line1Label: "住所1",
    line1Placeholder: "住所",
    line2Label: "住所2",
    line2Placeholder: "アパート、ユニット番号など（任意）",
    cityLabel: "街",
    postalCodeLabel: "郵便番号",
    stateLabel: "州",
    fullNamePlaceholder: "名前と苗字",
    countryLabel: "国",
    currencyLabel: "通貨",
    bankLabel: "バンクを選択",
    redirectText: "注文を送信すると、安全に購入を完了するためにリダイレクトされます。",
    bankDetailsText: "これらの詳細を送信すると、支払いを行うための銀行口座情報が表示されます。必ずメモを取ってください。",
    orPayUsing: "またはを使用して支払う",
    addNewCard: "新しいカードを追加",
    useExisitingSavedCards: "既存の保存済みカードを使用する",
    saveCardDetails: "カードの詳細を保存",
    addBankAccount: "銀行口座を追加",
    achBankDebitTerms: (function (str) {
        return "口座番号を提供し、この支払いを確認することにより、" + str + " および支払いサービス プロバイダーである Hyperswitch が、銀行に口座からの引き落としの指示を送信し、その指示に従って口座からの引き落としの銀行に指示を送信することを承認したことになります。お客様は、銀行との契約条件に基づいて、銀行から返金を受ける権利があります。払い戻しは、アカウントが引き落とされた日から 8 週間以内に請求する必要があります。";
      }),
    sepaDebitTerms: (function (str) {
        return "支払い情報を提供し、この支払いを確認することにより、お客様は、(A) 当社の支払いサービス プロバイダーである " + str + " および Hyperswitch および/またはそのローカル サービス プロバイダーである PPRO が、お客様の銀行にお客様の口座から引き落とされる指示を送信すること、および (B) 銀行がその指示に従って口座から引き落としを行います。 お客様の権利の一部として、お客様は銀行との契約条件に基づいて銀行から返金を受ける権利があります。 払い戻しは、アカウントの引き落とし日から 8 週間以内に請求する必要があります。 お客様の権利については、銀行から入手できる明細書で説明されています。 お客様は、将来の引き落としに関する通知を、発生の 2 日前までに受け取ることに同意するものとします。";
      }),
    becsDebitTerms: "銀行口座の詳細を提供し、この支払いを確認することにより、お客様は、この口座振替リクエストおよび口座振替リクエストのサービス契約に同意し、Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 口座振替ユーザー ID 番号 507156 (「ストライプ」) に口座振替を許可することになります。 Hyperswitch Payment Widget (以下「マーチャント」) に代わって、バルク電子決済システム (BECS) を通じて、マーチャントから別途通知された金額についてのアカウントを作成します。あなたは、自分がアカウント所有者であるか、上記のアカウントの承認された署名者のいずれかであることを証明します。",
    cardTerms: (function (str) {
        return "カード情報を提供することにより、" + str + " が規約に従って将来の支払いをカードに請求できるようになります。";
      }),
    payNowButton: "今払う",
    cardNumberEmptyText: "カード番号を空にすることはできません",
    cardExpiryDateEmptyText: "カードの有効期限を空にすることはできません",
    cvcNumberEmptyText: "CVC 番号を空にすることはできません",
    enterFieldsText: "すべてのフィールドに入力してください",
    enterValidDetailsText: "有効な詳細を入力してください",
    card: "カード",
    surchargeMsgAmount: (function (str) {
        return "この取引には " + str + " の追加料金が適用されます";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "この取引には " + str + " までの追加料金が適用されます";
      }),
    billingNameLabel: "課金名",
    billingNamePlaceholder: "名前と苗字",
    cardHolderName: "クレジットカード名義人氏名"
  },
  {
    locale: "de",
    cardNumberLabel: "Kartennummer",
    localeDirection: "ltr",
    inValidCardErrorText: "Kartennummer ist ungültig.",
    inCompleteCVCErrorText: "Der Sicherheitscode Ihrer Karte ist unvollständig.",
    inCompleteExpiryErrorText: "Das Ablaufdatum Ihrer Karte ist unvollständig.",
    pastExpiryErrorText: "Das Ablaufjahr Ihrer Karte liegt in der Vergangenheit.",
    poweredBy: "Unterstützt von Hyperswitch",
    validThruText: "Ablauf",
    sortCodeText: "Bankleitzahl",
    cvcTextLabel: "CVC",
    emailLabel: "Email",
    accountNumberText: "Accountnummer",
    fullNameLabel: "Vollständiger Name",
    line1Label: "Adresszeile 1",
    line1Placeholder: "Adresse",
    line2Label: "Adresszeile 2",
    line2Placeholder: "Wohnung, Einheitennummer usw. (optional)",
    cityLabel: "Stadt",
    postalCodeLabel: "Postleitzahl",
    stateLabel: "Zustand",
    fullNamePlaceholder: "Vor-und Nachname",
    countryLabel: "Land",
    currencyLabel: "Währung",
    bankLabel: "Wählen Sie Bank",
    redirectText: "Nach dem Absenden Ihrer Bestellung werden Sie weitergeleitet, um Ihren Kauf sicher abzuschließen.",
    bankDetailsText: "Nach Übermittlung dieser Daten erhalten Sie die Bankverbindung, um die Zahlung durchführen zu können. Bitte notieren Sie sich dies unbedingt.",
    orPayUsing: "Oder zahlen Sie mit",
    addNewCard: "Kredit-/Debitkarte hinzufügen",
    useExisitingSavedCards: "Verwenden Sie gespeicherte Debit-/Kreditkarten",
    saveCardDetails: "Kartendetails speichern",
    addBankAccount: "Bankkonto hinzufügen",
    achBankDebitTerms: (function (str) {
        return "Indem Sie Ihre Kontonummer angeben und diese Zahlung bestätigen, autorisieren Sie diese " + str + " und Hyperswitch, unser Zahlungsdienstleister, um Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und Ihre Bank, um Ihr Konto gemäß diesen Anweisungen zu belasten. Sie haben gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank Anspruch auf eine Rückerstattung von Ihrer Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden.";
      }),
    sepaDebitTerms: (function (str) {
        return "Indem Sie Ihre Zahlungsinformationen angeben und diese Zahlung bestätigen, autorisieren Sie (A) " + str + " und Hyperswitch, unser Zahlungsdienstleister und/oder PPRO, sein lokaler Dienstleister, um Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und (B) Ihre Bank, um Ihr Konto gemäß diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank Anspruch auf eine Rückerstattung von Ihrer Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden. Ihre Rechte werden in einer Abrechnung erläutert, die Sie bei Ihrer Bank erhalten können. Sie stimmen zu, Benachrichtigungen über zukünftige Belastungen bis zu 2 Tage vor deren Auftreten zu erhalten.";
      }),
    becsDebitTerms: "Indem Sie Ihre Bankkontodaten angeben und diese Zahlung bestätigen, stimmen Sie dieser Lastschriftanforderung und der Servicevereinbarung für Lastschriftanträge zu und ermächtigen Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Lastschriftbenutzer-ID-Nummer 507156 („Hyperswitch“), Ihr Konto zu belasten Konto über das Bulk Electronic Clearing System (BECS) im Namen von Hyperswitch Payment Widget (der „Händler“) für alle Beträge, die Ihnen vom Händler gesondert mitgeteilt werden. Sie bestätigen, dass Sie entweder Kontoinhaber oder Zeichnungsberechtigter des oben aufgeführten Kontos sind.",
    cardTerms: (function (str) {
        return "Durch die Angabe Ihrer Karteninformationen stimmen Sie zu " + str + " Ihre Karte gemäß deren Bedingungen für zukünftige Zahlungen zu belasten.";
      }),
    payNowButton: "Zahlen Sie jetzt",
    cardNumberEmptyText: "Die Kartennummer darf nicht leer sein",
    cardExpiryDateEmptyText: "Das Ablaufdatum der Karte darf nicht leer sein",
    cvcNumberEmptyText: "Die CVC-Nummer darf nicht leer sein",
    enterFieldsText: "Bitte füllen Sie alle Felder aus",
    enterValidDetailsText: "Bitte geben Sie gültige Daten ein",
    card: "Karte",
    surchargeMsgAmount: (function (str) {
        return "Für diese Transaktion wird ein Zuschlag in Höhe von " + str + " erhoben";
      }),
    surchargeMsgAmountForCard: (function (str) {
        return "Für diese Transaktion wird ein Zuschlagsbetrag von bis zu " + str + " erhoben";
      }),
    billingNameLabel: "Abrechnungsname",
    billingNamePlaceholder: "Vor-und Nachname",
    cardHolderName: "Name des Karteninhabers"
  }
];

export {
  defaultLocale ,
  localeStrings ,
}
/* No side effect */
