// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var make = React.lazy(function (param) {
      return import("./PaymentElementRenderer.bs.js");
    });

export {
  make ,
}
/* make Not a pure module */
