// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as PhoneNumberPaymentInput$OrcaPaymentPage from "../Components/PhoneNumberPaymentInput.bs.js";

function getPaymentMethodsFieldsOrder(paymentMethodField) {
  if (typeof paymentMethodField === "number") {
    switch (paymentMethodField) {
      case /* InfoElement */2 :
          return 99;
      case /* AddressLine1 */8 :
          return 4;
      case /* AddressLine2 */9 :
          return 5;
      case /* AddressCity */10 :
          return 6;
      case /* AddressPincode */12 :
          return 9;
      case /* StateAndCity */11 :
      case /* AddressState */13 :
          return 7;
      case /* CardNumber */15 :
          return 0;
      case /* CardExpiryMonth */16 :
      case /* CardExpiryYear */17 :
      case /* CardExpiryMonthAndYear */18 :
          return 1;
      case /* CardCvc */19 :
      case /* CardExpiryAndCvc */20 :
          return 2;
      default:
        return 3;
    }
  } else {
    switch (paymentMethodField.TAG | 0) {
      case /* CountryAndPincode */1 :
      case /* AddressCountry */2 :
          return 8;
      default:
        return 3;
    }
  }
}

function sortPaymentMethodFields(firstPaymentMethodField, secondPaymentMethodField) {
  return getPaymentMethodsFieldsOrder(firstPaymentMethodField) - getPaymentMethodsFieldsOrder(secondPaymentMethodField) | 0;
}

var defaultPaymentFieldsInfo_fields = [];

var defaultPaymentFieldsInfo = {
  paymentMethodName: "",
  fields: defaultPaymentFieldsInfo_fields,
  icon: undefined,
  displayName: "",
  miniIcon: undefined
};

var defaultPaymentMethodContent_fields = [];

var defaultPaymentMethodContent_paymentFlow = [];

var defaultPaymentMethodContent_bankNames = [];

var defaultPaymentMethodContent = {
  paymentMethodName: "",
  fields: defaultPaymentMethodContent_fields,
  paymentFlow: defaultPaymentMethodContent_paymentFlow,
  handleUserError: false,
  methodType: "",
  bankNames: defaultPaymentMethodContent_bankNames
};

var defaultPaymentMethodFields_fields = [];

var defaultPaymentMethodFields = {
  paymentMethodName: "",
  fields: defaultPaymentMethodFields_fields,
  icon: undefined,
  displayName: "",
  miniIcon: undefined
};

function icon(sizeOpt, widthOpt, name) {
  var size = sizeOpt !== undefined ? sizeOpt : 22;
  var width = widthOpt !== undefined ? widthOpt : size;
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: name,
              size: size,
              width: width
            });
}

var paymentMethodsFields = [
  {
    paymentMethodName: "afterpay_clearpay",
    fields: [
      /* Email */0,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "afterpay")),
    displayName: "After Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "google_pay",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "google_pay")),
    displayName: "Google Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "apple_pay",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "apple_pay")),
    displayName: "Apple Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "mb_way",
    fields: [
      {
        TAG: /* SpecialField */0,
        _0: React.createElement(PhoneNumberPaymentInput$OrcaPaymentPage.make, {})
      },
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "mbway")),
    displayName: "Mb Way",
    miniIcon: undefined
  },
  {
    paymentMethodName: "mobile_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "mobilepay")),
    displayName: "Mobile Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ali_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "alipay")),
    displayName: "Ali Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "we_chat_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "wechatpay")),
    displayName: "WeChat",
    miniIcon: undefined
  },
  {
    paymentMethodName: "affirm",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(20, undefined, "affirm")),
    displayName: "Affirm",
    miniIcon: undefined
  },
  {
    paymentMethodName: "crypto_currency",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "crypto")),
    displayName: "Crypto",
    miniIcon: undefined
  },
  {
    paymentMethodName: "card",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "klarna",
    fields: [
      /* Email */0,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "klarna")),
    displayName: "Klarna",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sofort",
    fields: [
      /* FullName */1,
      /* Email */0,
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "sofort")),
    displayName: "Sofort",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bacs_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "bank")),
    displayName: "BACS Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sepa_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "sepa")),
    displayName: "SEPA Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sepa_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "sepa")),
    displayName: "SEPA Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "giropay",
    fields: [
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "giropay")),
    displayName: "GiroPay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "eps",
    fields: [
      /* Bank */4,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "eps")),
    displayName: "EPS",
    miniIcon: undefined
  },
  {
    paymentMethodName: "walley",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "walley")),
    displayName: "Walley",
    miniIcon: undefined
  },
  {
    paymentMethodName: "pay_bright",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "paybright")),
    displayName: "Pay Bright",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bacs_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BACS Debit",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "becs_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BECS Debit",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "blik",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "blik")),
    displayName: "Blik",
    miniIcon: undefined
  },
  {
    paymentMethodName: "trustly",
    fields: [
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "trustly")),
    displayName: "Trustly",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bancontact_card",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "bancontact")),
    displayName: "Bancontact Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_czech_republic",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking CzechR",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_slovakia",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Slovakia",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_finland",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Finland",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_poland",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Poland",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ideal",
    fields: [
      /* Bank */4,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "ideal")),
    displayName: "iDEAL",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ban_connect",
    fields: [],
    icon: undefined,
    displayName: "Ban Connect",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_bank_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "ach-bank-debit")),
    displayName: "ACH Direct Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "przelewy24",
    fields: [
      /* Email */0,
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "p24")),
    displayName: "Przelewy24",
    miniIcon: undefined
  },
  {
    paymentMethodName: "interac",
    fields: [
      /* Email */0,
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "interac")),
    displayName: "Interac",
    miniIcon: undefined
  },
  {
    paymentMethodName: "twint",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "twint")),
    displayName: "Twint",
    miniIcon: undefined
  },
  {
    paymentMethodName: "vipps",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "vipps")),
    displayName: "Vipps",
    miniIcon: undefined
  },
  {
    paymentMethodName: "dana",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "dana")),
    displayName: "Dana",
    miniIcon: undefined
  },
  {
    paymentMethodName: "go_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "go_pay")),
    displayName: "Go Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "kakao_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "kakao_pay")),
    displayName: "Kakao Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "gcash",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "gcash")),
    displayName: "GCash",
    miniIcon: undefined
  },
  {
    paymentMethodName: "momo",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "momo")),
    displayName: "Momo",
    miniIcon: undefined
  },
  {
    paymentMethodName: "touch_n_go",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "touch_n_go")),
    displayName: "Touch N Go",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bizum",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "bizum")),
    displayName: "Bizum",
    miniIcon: undefined
  },
  {
    paymentMethodName: "classic",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(50, undefined, "cashtocode")),
    displayName: "Cash / Voucher",
    miniIcon: Caml_option.some(icon(19, undefined, "cashtocode"))
  },
  {
    paymentMethodName: "online_banking_fpx",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_fpx")),
    displayName: "Online Banking Fpx",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_thailand",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_thailand")),
    displayName: "Online Banking Thailand",
    miniIcon: undefined
  },
  {
    paymentMethodName: "alma",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "alma")),
    displayName: "Alma",
    miniIcon: undefined
  },
  {
    paymentMethodName: "atome",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "atome")),
    displayName: "Atome",
    miniIcon: undefined
  },
  {
    paymentMethodName: "multibanco",
    fields: [
      /* Email */0,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "multibanco")),
    displayName: "Multibanco",
    miniIcon: undefined
  },
  {
    paymentMethodName: "card_redirect",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "open_banking_uk",
    fields: [
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 50, "open_banking")),
    displayName: "Open Banking",
    miniIcon: Caml_option.some(icon(19, undefined, "open_banking"))
  },
  {
    paymentMethodName: "evoucher",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(50, undefined, "cashtocode")),
    displayName: "E-Voucher",
    miniIcon: Caml_option.some(icon(19, undefined, "cashtocode"))
  }
];

function getPaymentMethodsFieldTypeFromString(str, isBancontact) {
  switch (str) {
    case "user_address_city" :
        return /* AddressCity */10;
    case "user_address_line1" :
        return /* AddressLine1 */8;
    case "user_address_line2" :
        return /* AddressLine2 */9;
    case "user_address_pincode" :
        return /* AddressPincode */12;
    case "user_address_state" :
        return /* AddressState */13;
    case "user_bank" :
        return /* Bank */4;
    case "user_billing_name" :
        return /* BillingName */6;
    case "user_blik_code" :
        return /* BlikCode */14;
    case "user_card_cvc" :
        if (isBancontact) {
          return /* CardCvc */19;
        } else {
          return /* None */5;
        }
    case "user_card_expiry_month" :
        if (isBancontact) {
          return /* CardExpiryMonth */16;
        } else {
          return /* None */5;
        }
    case "user_card_expiry_year" :
        if (isBancontact) {
          return /* CardExpiryYear */17;
        } else {
          return /* None */5;
        }
    case "user_card_number" :
        if (isBancontact) {
          return /* CardNumber */15;
        } else {
          return /* None */5;
        }
    case "user_country" :
        return /* Country */3;
    case "user_email_address" :
        return /* Email */0;
    case "user_full_name" :
        return /* FullName */1;
    case "user_phone_number" :
        return /* PhoneNumber */7;
    default:
      return /* None */5;
  }
}

function getPaymentMethodsFieldTypeFromDict(dict) {
  var keysArr = Object.keys(dict);
  var key = Belt_Option.getWithDefault(Belt_Array.get(keysArr, 0), "");
  switch (key) {
    case "user_address_country" :
        var options = Utils$OrcaPaymentPage.getArrayValFromJsonDict(dict, "user_address_country", "options");
        var match = Belt_Option.getWithDefault(Belt_Array.get(options, 0), "");
        switch (match) {
          case "" :
              return /* None */5;
          case "ALL" :
              return {
                      TAG: /* AddressCountry */2,
                      _0: Country$OrcaPaymentPage.country.map(function (item) {
                            return item.countryName;
                          })
                    };
          default:
            return {
                    TAG: /* AddressCountry */2,
                    _0: options
                  };
        }
    case "user_currency" :
        var options$1 = Utils$OrcaPaymentPage.getArrayValFromJsonDict(dict, "user_currency", "options");
        return {
                TAG: /* Currency */3,
                _0: options$1
              };
    default:
      return /* None */5;
  }
}

function getFieldType(dict, isBancontact) {
  var fieldClass = Js_json.classify(Belt_Option.getWithDefault(Js_dict.get(dict, "field_type"), {}));
  if (typeof fieldClass === "number") {
    return /* None */5;
  }
  switch (fieldClass.TAG | 0) {
    case /* JSONString */0 :
        return getPaymentMethodsFieldTypeFromString(fieldClass._0, isBancontact);
    case /* JSONObject */2 :
        return getPaymentMethodsFieldTypeFromDict(fieldClass._0);
    case /* JSONNumber */1 :
    case /* JSONArray */3 :
        return /* None */5;
    
  }
}

function getRequiredFieldsFromJson(dict, isBancontact) {
  return {
          required_field: Utils$OrcaPaymentPage.getString(dict, "required_field", ""),
          display_name: Utils$OrcaPaymentPage.getString(dict, "display_name", ""),
          field_type: getFieldType(dict, isBancontact),
          value: Utils$OrcaPaymentPage.getString(dict, "value", "")
        };
}

var dynamicFieldsEnabledPaymentMethods = [
  "crypto_currency",
  "debit",
  "credit",
  "blik",
  "google_pay",
  "apple_pay",
  "bancontact_card"
];

function getIsBillingField(requiredFieldType) {
  if (typeof requiredFieldType !== "number") {
    if (requiredFieldType.TAG === /* AddressCountry */2) {
      return true;
    } else {
      return false;
    }
  }
  switch (requiredFieldType) {
    case /* AddressLine1 */8 :
    case /* AddressLine2 */9 :
    case /* AddressCity */10 :
    case /* AddressPincode */12 :
    case /* AddressState */13 :
        return true;
    default:
      return false;
  }
}

function getIsAnyBillingDetailEmpty(requiredFieldsValues, isBancontact) {
  return requiredFieldsValues.reduce((function (acc, item) {
                var requiredField = getRequiredFieldsFromJson(Utils$OrcaPaymentPage.getDictFromJson(item), isBancontact);
                if (getIsBillingField(requiredField.field_type) && requiredField.value === "") {
                  return true;
                } else {
                  return acc;
                }
              }), false);
}

function getPaymentMethodFields(paymentMethod, requiredFields, isSavedCardFlowOpt, isAllStoredCardsHaveNameOpt, isBancontactOpt, param) {
  var isSavedCardFlow = isSavedCardFlowOpt !== undefined ? isSavedCardFlowOpt : false;
  var isAllStoredCardsHaveName = isAllStoredCardsHaveNameOpt !== undefined ? isAllStoredCardsHaveNameOpt : false;
  var isBancontact = isBancontactOpt !== undefined ? isBancontactOpt : false;
  var requiredFieldsValues = Js_dict.values(Utils$OrcaPaymentPage.getDictFromJson(requiredFields));
  var isAnyBillingDetailEmpty = getIsAnyBillingDetailEmpty(requiredFieldsValues, isBancontact);
  var requiredFieldsArr = requiredFieldsValues.map(function (item) {
        var requiredField = getRequiredFieldsFromJson(Utils$OrcaPaymentPage.getDictFromJson(item), isBancontact);
        var isShowBillingField = getIsBillingField(requiredField.field_type) && isAnyBillingDetailEmpty;
        if ((requiredField.value === "" || isShowBillingField) && !(isSavedCardFlow && requiredField.display_name === "card_holder_name" && isAllStoredCardsHaveName)) {
          return requiredField.field_type;
        } else {
          return /* None */5;
        }
      });
  return requiredFieldsArr.concat(Belt_Option.getWithDefault(Caml_option.undefined_to_opt(paymentMethodsFields.find(function (x) {
                          return x.paymentMethodName === paymentMethod;
                        })), {
                  paymentMethodName: "",
                  fields: [],
                  icon: Caml_option.some(icon(19, 25, "")),
                  displayName: "",
                  miniIcon: Caml_option.some(icon(19, 25, ""))
                }).fields);
}

function getPaymentDetails(arr) {
  var finalArr = [];
  arr.map(function (item) {
        var optionalVal = paymentMethodsFields.find(function (i) {
              return i.paymentMethodName === item;
            });
        if (optionalVal !== undefined) {
          finalArr.push(optionalVal);
          return ;
        }
        
      });
  return finalArr;
}

var defaultCardNetworks_eligible_connectors = [];

var defaultCardNetworks = {
  card_network: /* NOTFOUND */13,
  eligible_connectors: defaultCardNetworks_eligible_connectors,
  surcharge_details: undefined
};

var defaultPaymentMethodType_payment_experience = [];

var defaultPaymentMethodType_card_networks = [];

var defaultPaymentMethodType_bank_names = [];

var defaultPaymentMethodType_bank_debits_connectors = [];

var defaultPaymentMethodType_bank_transfers_connectors = [];

var defaultPaymentMethodType = {
  payment_method_type: "",
  payment_experience: defaultPaymentMethodType_payment_experience,
  card_networks: defaultPaymentMethodType_card_networks,
  bank_names: defaultPaymentMethodType_bank_names,
  bank_debits_connectors: defaultPaymentMethodType_bank_debits_connectors,
  bank_transfers_connectors: defaultPaymentMethodType_bank_transfers_connectors,
  required_fields: null,
  surcharge_details: undefined
};

var defaultList_payment_methods = [];

var defaultList = {
  redirect_url: "",
  payment_methods: defaultList_payment_methods,
  mandate_payment: undefined,
  payment_type: ""
};

function getMethod(str) {
  switch (str) {
    case "bank_debit" :
        return /* BankDebit */5;
    case "bank_redirect" :
        return /* BankRedirect */3;
    case "bank_transfer" :
        return /* BankTransfer */4;
    case "card" :
        return /* Cards */0;
    case "crypto" :
        return /* Crypto */6;
    case "pay_later" :
        return /* PayLater */2;
    case "wallet" :
        return /* Wallets */1;
    default:
      return /* NONE */7;
  }
}

function getPaymentMethodType(str) {
  switch (str) {
    case "affirm" :
        return /* Affirm */1;
    case "afterpay_clearpay" :
        return /* AfterPay */2;
    case "apple_pay" :
        return /* ApplePay */5;
    case "credit" :
        return /* Card */{
                _0: /* Credit */0
              };
    case "crypto_currency" :
        return /* CryptoCurrency */6;
    case "debit" :
        return /* Card */{
                _0: /* Debit */1
              };
    case "google_pay" :
        return /* Gpay */3;
    case "klarna" :
        return /* Klarna */0;
    default:
      return /* NONE */7;
  }
}

function getPaymentExperienceType(str) {
  switch (str) {
    case "display_qr_code" :
        return /* QrFlow */2;
    case "invoke_sdk_client" :
        return /* InvokeSDK */0;
    case "redirect_to_url" :
        return /* RedirectToURL */1;
    default:
      return /* RedirectToURL */1;
  }
}

function getPaymentExperience(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_experience_type: getPaymentExperienceType(Utils$OrcaPaymentPage.getString(json, "payment_experience_type", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors")
                    };
            });
}

function getSurchargeDetails(dict) {
  var surchargDetails = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "surcharge_details"), Js_json.decodeObject), {});
  var displayTotalSurchargeAmount = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(surchargDetails, "display_total_surcharge_amount"), Js_json.decodeNumber), 0.0);
  if (displayTotalSurchargeAmount !== 0.0) {
    return {
            displayTotalSurchargeAmount: displayTotalSurchargeAmount
          };
  }
  
}

function getCardNetworks(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      card_network: CardUtils$OrcaPaymentPage.cardType(Utils$OrcaPaymentPage.getString(json, "card_network", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors"),
                      surcharge_details: getSurchargeDetails(json)
                    };
            });
}

function getBankNames(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
                return Utils$OrcaPaymentPage.getStrArray(json, "bank_name");
              }).reduce((function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                    });
                return acc;
              }), []);
}

function getAchConnectors(dict, str) {
  return Utils$OrcaPaymentPage.getStrArray(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), {}), "elligible_connectors");
}

function getPaymentMethodTypes(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_method_type: Utils$OrcaPaymentPage.getString(json, "payment_method_type", ""),
                      payment_experience: getPaymentExperience(json, "payment_experience"),
                      card_networks: getCardNetworks(json, "card_networks"),
                      bank_names: getBankNames(json, "bank_names"),
                      bank_debits_connectors: getAchConnectors(json, "bank_debit"),
                      bank_transfers_connectors: getAchConnectors(json, "bank_transfer"),
                      required_fields: Utils$OrcaPaymentPage.getJsonFromDict(json, "required_fields", null),
                      surcharge_details: getSurchargeDetails(json)
                    };
            });
}

function getMethodsArr(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_method: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                      payment_method_types: getPaymentMethodTypes(json, "payment_method_types")
                    };
            });
}

function getOptionalMandateType(dict, str) {
  return Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                return {
                        amount: Utils$OrcaPaymentPage.getInt(json, "amount", 0),
                        currency: Utils$OrcaPaymentPage.getString(json, "currency", "")
                      };
              }));
}

function getMandate(dict, str) {
  return Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                return {
                        single_use: getOptionalMandateType(json, "single_use"),
                        multi_use: getOptionalMandateType(json, "multi_use")
                      };
              }));
}

function itemToObjMapper(dict) {
  return {
          redirect_url: Utils$OrcaPaymentPage.getString(dict, "redirect_url", ""),
          payment_methods: getMethodsArr(dict, "payment_methods"),
          mandate_payment: getMandate(dict, "mandate_payment"),
          payment_type: Utils$OrcaPaymentPage.getString(dict, "payment_type", "")
        };
}

function buildFromPaymentList(plist) {
  var paymentMethodArr = plist.payment_methods;
  return paymentMethodArr.map(function (paymentMethodObject) {
                var methodType = paymentMethodObject.payment_method;
                var handleUserError = methodType === "wallet";
                return paymentMethodObject.payment_method_types.map(function (individualPaymentMethod) {
                            var paymentMethodName = individualPaymentMethod.payment_method_type;
                            var bankNames = individualPaymentMethod.bank_names;
                            var paymentExperience = individualPaymentMethod.payment_experience.map(function (experience) {
                                  return [
                                          experience.payment_experience_type,
                                          experience.eligible_connectors
                                        ];
                                });
                            return {
                                    paymentMethodName: paymentMethodName,
                                    fields: getPaymentMethodFields(paymentMethodName, individualPaymentMethod.required_fields, undefined, undefined, individualPaymentMethod.payment_method_type === "bancontact_card", undefined),
                                    paymentFlow: paymentExperience,
                                    handleUserError: handleUserError,
                                    methodType: methodType,
                                    bankNames: bankNames
                                  };
                          });
              }).reduce((function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                    });
                return acc;
              }), []);
}

function getPaymentMethodTypeFromList(list, paymentMethod, paymentMethodType) {
  return Caml_option.undefined_to_opt(Belt_Option.getWithDefault(Caml_option.undefined_to_opt(list.payment_methods.find(function (item) {
                            return item.payment_method === paymentMethod;
                          })), {
                    payment_method: "card",
                    payment_method_types: []
                  }).payment_method_types.find(function (item) {
                  return item.payment_method_type === paymentMethodType;
                }));
}

var dynamicFieldsToRenderOutsideBilling = [
  /* CardNumber */15,
  /* CardExpiryMonth */16,
  /* CardExpiryYear */17,
  /* CardExpiryMonthAndYear */18,
  /* CardCvc */19,
  /* CardExpiryAndCvc */20
];

export {
  getPaymentMethodsFieldsOrder ,
  sortPaymentMethodFields ,
  defaultPaymentFieldsInfo ,
  defaultPaymentMethodContent ,
  defaultPaymentMethodFields ,
  icon ,
  paymentMethodsFields ,
  getPaymentMethodsFieldTypeFromString ,
  getPaymentMethodsFieldTypeFromDict ,
  getFieldType ,
  getRequiredFieldsFromJson ,
  dynamicFieldsEnabledPaymentMethods ,
  getIsBillingField ,
  getIsAnyBillingDetailEmpty ,
  getPaymentMethodFields ,
  getPaymentDetails ,
  defaultCardNetworks ,
  defaultPaymentMethodType ,
  defaultList ,
  getMethod ,
  getPaymentMethodType ,
  getPaymentExperienceType ,
  getPaymentExperience ,
  getSurchargeDetails ,
  getCardNetworks ,
  getBankNames ,
  getAchConnectors ,
  getPaymentMethodTypes ,
  getMethodsArr ,
  getOptionalMandateType ,
  getMandate ,
  itemToObjMapper ,
  buildFromPaymentList ,
  getPaymentMethodTypeFromList ,
  dynamicFieldsToRenderOutsideBilling ,
}
/* paymentMethodsFields Not a pure module */
