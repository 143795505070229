// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultACHCreditTransfer = {
  account_number: "",
  bank_name: "",
  routing_number: "",
  swift_code: ""
};

var defaultBacsBankInstruction = {
  sort_code: "",
  account_number: "",
  account_holder_name: ""
};

var defaultNextAction = {
  redirectToUrl: "",
  type_: "",
  bank_transfer_steps_and_charges_details: undefined,
  session_token: undefined,
  image_data_url: undefined
};

var defaultIntent = {
  nextAction: defaultNextAction,
  status: "",
  paymentId: "",
  clientSecret: "",
  error_message: "",
  payment_method_type: "",
  manualRetryAllowed: false
};

function getAchCreditTransfer(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            account_number: Utils$OrcaPaymentPage.getString(json, "account_number", ""),
                            bank_name: Utils$OrcaPaymentPage.getString(json, "bank_name", ""),
                            routing_number: Utils$OrcaPaymentPage.getString(json, "routing_number", ""),
                            swift_code: Utils$OrcaPaymentPage.getString(json, "swift_code", "")
                          };
                  })), defaultACHCreditTransfer);
}

function getBacsBankInstructions(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            sort_code: Utils$OrcaPaymentPage.getString(json, "sort_code", ""),
                            account_number: Utils$OrcaPaymentPage.getString(json, "account_number", ""),
                            account_holder_name: Utils$OrcaPaymentPage.getString(json, "account_holder_name", "")
                          };
                  })), defaultBacsBankInstruction);
}

function getBankTransferDetails(dict, str) {
  return Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                return {
                        ach_credit_transfer: getAchCreditTransfer(json, "ach_credit_transfer")
                      };
              }));
}

function getNextAction(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            redirectToUrl: Utils$OrcaPaymentPage.getString(json, "redirect_to_url", ""),
                            type_: Utils$OrcaPaymentPage.getString(json, "type", ""),
                            bank_transfer_steps_and_charges_details: Caml_option.some(Utils$OrcaPaymentPage.getJsonObjFromDict(json, "bank_transfer_steps_and_charges_details", {})),
                            session_token: Caml_option.some(Utils$OrcaPaymentPage.getJsonObjFromDict(json, "session_token", {})),
                            image_data_url: Utils$OrcaPaymentPage.getString(json, "image_data_url", "")
                          };
                  })), defaultNextAction);
}

function itemToObjMapper(dict) {
  return {
          nextAction: getNextAction(dict, "next_action"),
          status: Utils$OrcaPaymentPage.getString(dict, "status", ""),
          paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
          clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
          error_message: Utils$OrcaPaymentPage.getString(dict, "error_message", ""),
          payment_method_type: Utils$OrcaPaymentPage.getString(dict, "payment_method_type", ""),
          manualRetryAllowed: Utils$OrcaPaymentPage.getBool(dict, "manual_retry_allowed", false)
        };
}

var defaultRedirectTourl = {
  returnUrl: "",
  url: ""
};

export {
  defaultACHCreditTransfer ,
  defaultBacsBankInstruction ,
  defaultRedirectTourl ,
  defaultNextAction ,
  defaultIntent ,
  getAchCreditTransfer ,
  getBacsBankInstructions ,
  getBankTransferDetails ,
  getNextAction ,
  itemToObjMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
