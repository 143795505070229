// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RenderIf$OrcaPaymentPage from "./Components/RenderIf.bs.js";
import * as InputField$OrcaPaymentPage from "./Components/InputField.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as CardElementShimmer$OrcaPaymentPage from "./Components/CardElementShimmer.bs.js";
import * as PaymentElementShimmer$OrcaPaymentPage from "./Components/PaymentElementShimmer.bs.js";
import * as SingleLineCardPaymentLazy$OrcaPaymentPage from "./SingleLineCardPaymentLazy.bs.js";
import * as PaymentElementRendererLazy$OrcaPaymentPage from "./PaymentElementRendererLazy.bs.js";

function RenderPaymentMethods(props) {
  var isFocus = props.isFocus;
  var handleElementFocus = props.handleElementFocus;
  var cvcProps = props.cvcProps;
  var expiryProps = props.expiryProps;
  var cardProps = props.cardProps;
  var paymentType = props.paymentType;
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var showLoader = match.showLoader;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var themeObj = match$1.themeObj;
  var blur = props.blurState ? "blur(2px)" : "";
  var frameRef = React.useRef(null);
  var tmp;
  switch (paymentType) {
    case /* Card */0 :
        tmp = React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(SingleLineCardPaymentLazy$OrcaPaymentPage.make, {
                        paymentType: paymentType,
                        cardProps: cardProps,
                        expiryProps: expiryProps,
                        cvcProps: cvcProps,
                        zipProps: props.zipProps,
                        handleElementFocus: handleElementFocus,
                        isFocus: isFocus
                      })),
              fallback: Caml_option.some(React.createElement(RenderIf$OrcaPaymentPage.make, {
                        condition: showLoader,
                        children: React.createElement(CardElementShimmer$OrcaPaymentPage.make, {})
                      }))
            });
        break;
    case /* Payment */1 :
        tmp = React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(PaymentElementRendererLazy$OrcaPaymentPage.make, {
                        paymentType: paymentType,
                        cardProps: cardProps,
                        expiryProps: expiryProps,
                        cvcProps: cvcProps,
                        countryProps: props.countryProps
                      })),
              fallback: Caml_option.some(React.createElement(RenderIf$OrcaPaymentPage.make, {
                        condition: showLoader,
                        children: React.createElement(PaymentElementShimmer$OrcaPaymentPage.make, {})
                      }))
            });
        break;
    case /* CardNumberElement */2 :
        tmp = React.createElement(InputField$OrcaPaymentPage.make, {
              isValid: cardProps[0],
              id: "card-number",
              setIsValid: cardProps[1],
              value: cardProps[2],
              onChange: cardProps[3],
              onBlur: cardProps[4],
              onFocus: handleElementFocus,
              type_: "tel",
              paymentType: paymentType,
              maxLength: cardProps[9],
              placeholder: "1234 1234 1234 1234",
              inputRef: cardProps[5],
              isFocus: isFocus
            });
        break;
    case /* CardExpiryElement */3 :
        tmp = React.createElement(InputField$OrcaPaymentPage.make, {
              isValid: expiryProps[0],
              id: "card-expiry",
              setIsValid: expiryProps[1],
              value: expiryProps[2],
              onChange: expiryProps[3],
              onBlur: expiryProps[4],
              onFocus: handleElementFocus,
              type_: "tel",
              paymentType: paymentType,
              maxLength: 7,
              placeholder: "MM / YY",
              inputRef: expiryProps[5],
              isFocus: isFocus
            });
        break;
    case /* CardCVCElement */4 :
        tmp = React.createElement(InputField$OrcaPaymentPage.make, {
              isValid: cvcProps[0],
              id: "card-cvc",
              setIsValid: cvcProps[1],
              value: cvcProps[2],
              onChange: cvcProps[4],
              onBlur: cvcProps[5],
              onFocus: handleElementFocus,
              type_: "tel",
              paymentType: paymentType,
              maxLength: 4,
              placeholder: "123",
              className: "tracking-widest w-auto",
              inputRef: cvcProps[6],
              isFocus: isFocus
            });
        break;
    case /* NONE */5 :
        tmp = null;
        break;
    
  }
  return React.createElement("div", {
              className: "flex flex-col justify-between ",
              dir: match$1.localeString.localeDirection,
              style: {
                background: "transparent",
                color: themeObj.colorText,
                fontFamily: themeObj.fontFamily,
                fontSize: themeObj.fontSizeBase,
                marginRight: "4px",
                marginLeft: "4px",
                filter: blur
              }
            }, React.createElement("div", {
                  ref: Caml_option.some(frameRef),
                  className: "m-auto flex justify-center md:h-auto  w-full h-auto "
                }, React.createElement("div", {
                      className: "w-full font-medium"
                    }, tmp)));
}

var make = RenderPaymentMethods;

export {
  make ,
}
/* react Not a pure module */
