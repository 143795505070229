// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function FullScreenDivDriver(props) {
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "driverMounted",
                  true
                ]]);
        }), []);
  return React.createElement("div", undefined);
}

var make = FullScreenDivDriver;

export {
  make ,
}
/* react Not a pure module */
