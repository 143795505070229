// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Loader$OrcaPaymentPage from "./Loader.bs.js";

function PaymentLoader(props) {
  var loaderUI = React.createElement("div", {
        className: "flex flex-col justify-center m-auto visible"
      }, React.createElement(Loader$OrcaPaymentPage.make, {}));
  return React.createElement("div", {
              className: "h-screen w-screen bg-black/80 flex m-auto items-center backdrop-blur-md"
            }, loaderUI);
}

var make = PaymentLoader;

export {
  make ,
}
/* react Not a pure module */
