// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function useScript(src) {
  var match = React.useState(function () {
        if (src !== "") {
          return "loading";
        } else {
          return "idle";
        }
      });
  var setStatus = match[1];
  React.useEffect((function () {
          if (src === "") {
            Curry._1(setStatus, (function (param) {
                    return "idle";
                  }));
          }
          var script = document.querySelector("script[src=\"" + src + "\"]");
          if (script == null) {
            var script$1 = document.createElement("script");
            script$1.src = src;
            script$1.async = true;
            script$1.setAttribute("data-status", "loading");
            document.body.appendChild(script$1);
            var setAttributeFromEvent = function ($$event) {
              Curry._1(setStatus, (function (param) {
                      if ($$event.type === "load") {
                        return "ready";
                      } else {
                        return "error";
                      }
                    }));
              script$1.setAttribute("data-status", $$event.type === "load" ? "ready" : "error");
            };
            script$1.addEventListener("load", setAttributeFromEvent);
            script$1.addEventListener("error", setAttributeFromEvent);
            return (function (param) {
                      script$1.removeEventListener("load", setAttributeFromEvent);
                      script$1.removeEventListener("error", setAttributeFromEvent);
                    });
          }
          Curry._1(setStatus, (function (param) {
                  return script.getAttribute("data-status");
                }));
        }), [src]);
  return match[0];
}

function updateKeys(dict, keyPair, setKeys) {
  var value = keyPair[1];
  var key = keyPair[0];
  var valueStr = Belt_Option.getWithDefault(Js_json.decodeString(value), "");
  var valueBool = Belt_Option.getWithDefault(Js_json.decodeBoolean(value), false);
  if (!Utils$OrcaPaymentPage.getDictIsSome(dict, key)) {
    return ;
  }
  switch (key) {
    case "iframeId" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "parentURL" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: prev.iframeId,
                            parentURL: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "publishableKey" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            iframeId: prev.iframeId,
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "sdkHandleConfirmPayment" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: prev.iframeId,
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: Utils$OrcaPaymentPage.getBool(dict, key, valueBool)
                          };
                  });
    default:
      return ;
  }
}

var defaultkeys = {
  clientSecret: undefined,
  publishableKey: "",
  iframeId: "",
  parentURL: "*",
  sdkHandleConfirmPayment: false
};

export {
  useScript ,
  updateKeys ,
  defaultkeys ,
}
/* react Not a pure module */
