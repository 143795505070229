// Generated by ReScript, PLEASE EDIT WITH CARE


var defaultPostalCode = {
  iso: "",
  format: "",
  regex: ""
};

export {
  defaultPostalCode ,
}
/* No side effect */
