// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";

function checkIsSafari(param) {
  var userAgentString = navigator.userAgent;
  var chromeAgent = userAgentString.indexOf("Chrome") > -1;
  var safariAgent = userAgentString.indexOf("Safari") > -1;
  if (chromeAgent && safariAgent || !safariAgent) {
    return false;
  } else {
    return true;
  }
}

var date = new Date();

function broswerInfo(param) {
  return [[
            "browser_info",
            Js_dict.fromArray([
                  [
                    "user_agent",
                    navigator.userAgent
                  ],
                  [
                    "accept_header",
                    "text\/html,application\/xhtml+xml,application\/xml;q=0.9,image\/webp,image\/apng,*\/*;q=0.8"
                  ],
                  [
                    "language",
                    navigator.language
                  ],
                  [
                    "color_depth",
                    screen.colorDepth
                  ],
                  [
                    "screen_height",
                    screen.height
                  ],
                  [
                    "screen_width",
                    screen.width
                  ],
                  [
                    "time_zone",
                    date.getTimezoneOffset()
                  ],
                  [
                    "java_enabled",
                    true
                  ],
                  [
                    "java_script_enabled",
                    true
                  ]
                ])
          ]];
}

export {
  checkIsSafari ,
  date ,
  broswerInfo ,
}
/* date Not a pure module */
