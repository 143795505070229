// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";

function cardPaymentBody(cardNumber, month, year, cardHolderName, cvcNumber, cardBrand) {
  return [
          [
            "payment_method",
            "card"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "card",
                    Js_dict.fromArray([
                            [
                              "card_number",
                              CardUtils$OrcaPaymentPage.clearSpaces(cardNumber)
                            ],
                            [
                              "card_exp_month",
                              month
                            ],
                            [
                              "card_exp_year",
                              year
                            ],
                            [
                              "card_holder_name",
                              cardHolderName
                            ],
                            [
                              "card_cvc",
                              cvcNumber
                            ],
                            [
                              "card_issuer",
                              ""
                            ]
                          ].concat(cardBrand))
                  ]])
          ]
        ];
}

function bancontactBody(param) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "bancontact_card"
          ]
        ];
}

function savedCardBody(paymentToken, customerId, cvcNumber) {
  return [
          [
            "payment_method",
            "card"
          ],
          [
            "payment_token",
            paymentToken
          ],
          [
            "customer_id",
            customerId
          ],
          [
            "card_cvc",
            cvcNumber
          ]
        ];
}

function mandateBody(paymentType) {
  return [
          [
            "mandate_data",
            Js_dict.fromArray([[
                    "customer_acceptance",
                    Js_dict.fromArray([
                          [
                            "acceptance_type",
                            "online"
                          ],
                          [
                            "accepted_at",
                            new Date(Date.now()).toISOString()
                          ],
                          [
                            "online",
                            Js_dict.fromArray([[
                                    "user_agent",
                                    navigator.userAgent
                                  ]])
                          ]
                        ])
                  ]])
          ],
          [
            "setup_future_usage",
            "off_session"
          ],
          [
            "payment_type",
            paymentType === "" ? null : paymentType
          ]
        ];
}

function achBankDebitBody(email, bank, cardHolderName, line1, line2, country, city, postalCode, state, paymentType) {
  return [
            [
              "payment_method",
              "bank_debit"
            ],
            [
              "setup_future_usage",
              "off_session"
            ],
            [
              "payment_method_type",
              "ach"
            ],
            [
              "payment_method_data",
              Js_dict.fromArray([[
                      "bank_debit",
                      Js_dict.fromArray([[
                              "ach_bank_debit",
                              Js_dict.fromArray([
                                    [
                                      "billing_details",
                                      Js_dict.fromArray([
                                            [
                                              "name",
                                              cardHolderName
                                            ],
                                            [
                                              "email",
                                              email
                                            ],
                                            [
                                              "address",
                                              Js_dict.fromArray([
                                                    [
                                                      "line1",
                                                      line1
                                                    ],
                                                    [
                                                      "line2",
                                                      line2
                                                    ],
                                                    [
                                                      "city",
                                                      city
                                                    ],
                                                    [
                                                      "state",
                                                      state
                                                    ],
                                                    [
                                                      "zip",
                                                      postalCode
                                                    ],
                                                    [
                                                      "country",
                                                      country
                                                    ]
                                                  ])
                                            ]
                                          ])
                                    ],
                                    [
                                      "account_number",
                                      bank.accountNumber
                                    ],
                                    [
                                      "bank_account_holder_name",
                                      bank.accountHolderName
                                    ],
                                    [
                                      "routing_number",
                                      bank.routingNumber
                                    ],
                                    [
                                      "bank_type",
                                      bank.accountType
                                    ]
                                  ])
                            ]])
                    ]])
            ]
          ].concat(mandateBody(paymentType));
}

function sepaBankDebitBody(fullName, email, data, line1, line2, country, city, postalCode, state) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "sepa"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_debit",
                    Js_dict.fromArray([[
                            "sepa_bank_debit",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "name",
                                            fullName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Js_dict.fromArray([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "zip",
                                                    postalCode
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "iban",
                                    data.iban
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    data.accountHolderName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function bacsBankDebitBody(email, accNum, sortCode, line1, line2, city, zip, state, country, bankAccountHolderName) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "bacs"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_debit",
                    Js_dict.fromArray([[
                            "bacs_bank_debit",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "name",
                                            bankAccountHolderName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Js_dict.fromArray([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "zip",
                                                    zip
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    bankAccountHolderName
                                  ],
                                  [
                                    "sort_code",
                                    sortCode
                                  ],
                                  [
                                    "account_number",
                                    accNum
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function becsBankDebitBody(fullName, email, data, line1, line2, country, city, postalCode, state) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "becs"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_debit",
                    Js_dict.fromArray([[
                            "becs_bank_debit",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "name",
                                            fullName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Js_dict.fromArray([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "zip",
                                                    postalCode
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "bsb_number",
                                    data.sortCode
                                  ],
                                  [
                                    "account_number",
                                    data.accountNumber
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    data.accountHolderName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function klarnaRedirectionBody(fullName, email, country, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "name",
            fullName
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "klarna_redirect",
                            Js_dict.fromArray([
                                  [
                                    "billing_email",
                                    email
                                  ],
                                  [
                                    "billing_country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function klarnaSDKbody(token, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "klarna_sdk",
                            Js_dict.fromArray([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function paypalRedirectionBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "paypal_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function paypalSdkBody(token, connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "paypal_sdk",
                            Js_dict.fromArray([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function gpayBody(payObj, connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "google_pay",
                            Js_dict.fromArray([
                                  [
                                    "type",
                                    payObj.paymentMethodData.type
                                  ],
                                  [
                                    "description",
                                    payObj.paymentMethodData.description
                                  ],
                                  [
                                    "info",
                                    Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.info, /* SnakeCase */1)
                                  ],
                                  [
                                    "tokenization_data",
                                    Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.tokenizationData, /* SnakeCase */1)
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function gpayRedirectBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "google_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function gPayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "google_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayBody(token, connectors) {
  var dict = Belt_Option.getWithDefault(Js_json.decodeObject(token), {});
  var paymentDataString = window.btoa(JSON.stringify(Belt_Option.getWithDefault(Js_dict.get(dict, "paymentData"), {})));
  dict["paymentData"] = paymentDataString;
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "apple_pay",
                            Utils$OrcaPaymentPage.transformKeys(dict, /* SnakeCase */1)
                          ]])
                  ]])
          ]
        ];
}

function applePayRedirectBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "apple_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "apple_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function affirmBody(param) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "affirm"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "affirm_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function cryptoBody(currency) {
  return [
          [
            "payment_method",
            "crypto"
          ],
          [
            "payment_method_type",
            "crypto_currency"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "crypto",
                    Js_dict.fromArray([[
                            "pay_currency",
                            currency
                          ]])
                  ]])
          ]
        ];
}

function afterpayRedirectionBody(fullName, email) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "afterpay_clearpay"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "afterpay_clearpay_redirect",
                            Js_dict.fromArray([
                                  [
                                    "billing_email",
                                    email
                                  ],
                                  [
                                    "billing_name",
                                    fullName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function giroPayBody(name, ibanOpt, param) {
  var iban = ibanOpt !== undefined ? ibanOpt : "";
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "giropay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "giropay",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_account_iban",
                                    iban
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function sofortBody(country, name, email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "sofort"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "sofort",
                            Js_dict.fromArray([
                                  [
                                    "country",
                                    country === "" ? "US" : country
                                  ],
                                  [
                                    "preferred_language",
                                    "en"
                                  ],
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "billing_name",
                                            name
                                          ],
                                          [
                                            "email",
                                            email === "" ? "test@gmail.com" : email
                                          ]
                                        ])
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function iDealBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "ideal"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "ideal",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function epsBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "eps"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "eps",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function achBankTransferBody(email, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "ach"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_transfer",
                    Js_dict.fromArray([[
                            "ach_bank_transfer",
                            Js_dict.fromArray([[
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function bacsBankTransferBody(email, name, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "bacs"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_transfer",
                    Js_dict.fromArray([[
                            "bacs_bank_transfer",
                            Js_dict.fromArray([[
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "name",
                                            name
                                          ]
                                        ])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function sepaBankTransferBody(email, name, country, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "sepa"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_transfer",
                    Js_dict.fromArray([[
                            "sepa_bank_transfer",
                            Js_dict.fromArray([
                                  [
                                    "billing_details",
                                    Js_dict.fromArray([
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "name",
                                            name
                                          ]
                                        ])
                                  ],
                                  [
                                    "country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function blikBody(blikCode) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "blik"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "blik",
                            Js_dict.fromArray([[
                                    "blik_code",
                                    blikCode
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function p24Body(email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "przelewy24"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "przelewy24",
                            Js_dict.fromArray([[
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function interacBody(email, country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "interac"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "interac",
                            Js_dict.fromArray([
                                  [
                                    "email",
                                    email
                                  ],
                                  [
                                    "country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function mobilePayBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "mobile_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "mobile_pay",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function aliPayRedirectBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "ali_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "ali_pay_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function aliPayQrBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "ali_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "ali_pay_qr",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function weChatPayRedirectBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "we_chat_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "we_chat_pay_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function weChatPayQrBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "we_chat_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "we_chat_pay_qr",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function trustlyBody(country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "trustly"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "trustly",
                            Js_dict.fromArray([[
                                    "country",
                                    country
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function finlandOB(param) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_finland"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_finland",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function polandOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_poland"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_poland",
                            Js_dict.fromArray([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function czechOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_czech_republic"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_czech_republic",
                            Js_dict.fromArray([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function slovakiaOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_slovakia"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_slovakia",
                            Js_dict.fromArray([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function walleyBody(param) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "walley"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "walley_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function payBrightBody(param) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "pay_bright"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "pay_bright_redirect",
                            Js_dict.fromArray([])
                          ]])
                  ]])
          ]
        ];
}

function mbWayBody(phoneNumber) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "mb_way"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "mb_way_redirect",
                            Js_dict.fromArray([[
                                    "telephone_number",
                                    phoneNumber
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function twintBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "twint"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "twint_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function vippsBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "vipps"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "vipps_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function danaBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "dana"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "dana_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function goPayBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "go_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "go_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function kakaoPayBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "kakao_pay"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "kakao_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function gcashBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "gcash"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "gcash_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function momoBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "momo"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "momo_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function touchNGoBody(param) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "touch_n_go"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "wallet",
                    Js_dict.fromArray([[
                            "touch_n_go_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function bizumBody(param) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "bizum"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "bizum",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function rewardBody(paymentMethodType) {
  return [
          [
            "payment_method",
            "reward"
          ],
          [
            "payment_method_type",
            paymentMethodType
          ],
          [
            "payment_method_data",
            "reward"
          ]
        ];
}

function fpxOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_fpx"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_fpx",
                            Js_dict.fromArray([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function thailandOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_thailand"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "online_banking_thailand",
                            Js_dict.fromArray([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function almaBody(param) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "alma"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "alma",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function atomeBody(param) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "atome"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "pay_later",
                    Js_dict.fromArray([[
                            "atome_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function multibancoBody(email) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "payment_method_type",
            "multibanco"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_transfer",
                    Js_dict.fromArray([[
                            "multibanco_bank_transfer",
                            Js_dict.fromArray([[
                                    "billing_details",
                                    Js_dict.fromArray([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function cardRedirectBody(param) {
  return [
          [
            "payment_method",
            "card_redirect"
          ],
          [
            "payment_method_type",
            "card_redirect"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "card_redirect",
                    Js_dict.fromArray([[
                            "card_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function openBankingUKBody(country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "open_banking_uk"
          ],
          [
            "payment_method_data",
            Js_dict.fromArray([[
                    "bank_redirect",
                    Js_dict.fromArray([[
                            "open_banking_uk",
                            Js_dict.fromArray([[
                                    "country",
                                    country
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function getPaymentBody(paymentMethod, fullName, email, country, bank, blikCode, paymentExperienceOpt, phoneNumber, currency) {
  var paymentExperience = paymentExperienceOpt !== undefined ? paymentExperienceOpt : /* RedirectToURL */1;
  switch (paymentMethod) {
    case "affirm" :
        return affirmBody(undefined);
    case "afterpay_clearpay" :
        return afterpayRedirectionBody(fullName, email);
    case "ali_pay" :
        switch (paymentExperience) {
          case /* InvokeSDK */0 :
          case /* RedirectToURL */1 :
              return aliPayRedirectBody(undefined);
          case /* QrFlow */2 :
              return aliPayQrBody(undefined);
          
        }
    case "alma" :
        return almaBody(undefined);
    case "atome" :
        return atomeBody(undefined);
    case "bizum" :
        return bizumBody(undefined);
    case "blik" :
        return blikBody(blikCode);
    case "card_redirect" :
        return cardRedirectBody(undefined);
    case "crypto_currency" :
        return cryptoBody(currency);
    case "dana" :
        return danaBody(undefined);
    case "eps" :
        return epsBody(fullName, bank);
    case "classic" :
    case "evoucher" :
        return rewardBody(paymentMethod);
    case "gcash" :
        return gcashBody(undefined);
    case "giropay" :
        return giroPayBody(fullName, undefined, undefined);
    case "go_pay" :
        return goPayBody(undefined);
    case "ideal" :
        return iDealBody(fullName, bank);
    case "interac" :
        return interacBody(email, country);
    case "kakao_pay" :
        return kakaoPayBody(undefined);
    case "mb_way" :
        return mbWayBody(phoneNumber);
    case "mobile_pay" :
        return mobilePayBody(undefined);
    case "momo" :
        return momoBody(undefined);
    case "multibanco" :
        return multibancoBody(email);
    case "online_banking_czech_republic" :
        return czechOB(bank);
    case "online_banking_finland" :
        return finlandOB(undefined);
    case "online_banking_fpx" :
        return fpxOBBody(bank);
    case "online_banking_poland" :
        return polandOB(bank);
    case "online_banking_slovakia" :
        return slovakiaOB(bank);
    case "online_banking_thailand" :
        return thailandOBBody(bank);
    case "open_banking_uk" :
        return openBankingUKBody(country);
    case "pay_bright" :
        return payBrightBody(undefined);
    case "przelewy24" :
        return p24Body(email);
    case "sofort" :
        return sofortBody(country, fullName, email);
    case "touch_n_go" :
        return touchNGoBody(undefined);
    case "trustly" :
        return trustlyBody(country);
    case "twint" :
        return twintBody(undefined);
    case "vipps" :
        return vippsBody(undefined);
    case "walley" :
        return walleyBody(undefined);
    case "we_chat_pay" :
        switch (paymentExperience) {
          case /* InvokeSDK */0 :
          case /* RedirectToURL */1 :
              return weChatPayRedirectBody(undefined);
          case /* QrFlow */2 :
              return weChatPayQrBody(undefined);
          
        }
    default:
      return [];
  }
}

export {
  cardPaymentBody ,
  bancontactBody ,
  savedCardBody ,
  mandateBody ,
  achBankDebitBody ,
  sepaBankDebitBody ,
  bacsBankDebitBody ,
  becsBankDebitBody ,
  klarnaRedirectionBody ,
  klarnaSDKbody ,
  paypalRedirectionBody ,
  paypalSdkBody ,
  gpayBody ,
  gpayRedirectBody ,
  gPayThirdPartySdkBody ,
  applePayBody ,
  applePayRedirectBody ,
  applePayThirdPartySdkBody ,
  affirmBody ,
  cryptoBody ,
  afterpayRedirectionBody ,
  giroPayBody ,
  sofortBody ,
  iDealBody ,
  epsBody ,
  achBankTransferBody ,
  bacsBankTransferBody ,
  sepaBankTransferBody ,
  blikBody ,
  p24Body ,
  interacBody ,
  mobilePayBody ,
  aliPayRedirectBody ,
  aliPayQrBody ,
  weChatPayRedirectBody ,
  weChatPayQrBody ,
  trustlyBody ,
  finlandOB ,
  polandOB ,
  czechOB ,
  slovakiaOB ,
  walleyBody ,
  payBrightBody ,
  mbWayBody ,
  twintBody ,
  vippsBody ,
  danaBody ,
  goPayBody ,
  kakaoPayBody ,
  gcashBody ,
  momoBody ,
  touchNGoBody ,
  bizumBody ,
  rewardBody ,
  fpxOBBody ,
  thailandOBBody ,
  almaBody ,
  atomeBody ,
  multibancoBody ,
  cardRedirectBody ,
  openBankingUKBody ,
  getPaymentBody ,
}
/* Utils-OrcaPaymentPage Not a pure module */
