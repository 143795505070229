// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";

var defaultCardDetails = {
  scheme: undefined,
  last4Digits: "",
  expiryMonth: "",
  expiryYear: "",
  cardToken: "",
  cardHolderName: undefined
};

var defaultCustomerMethods = {
  paymentToken: "",
  customerId: "",
  paymentMethod: "",
  paymentMethodIssuer: undefined,
  card: defaultCardDetails
};

var defaultLayout = {
  defaultCollapsed: false,
  radios: false,
  spacedAccordionItems: false,
  maxAccordionItems: 4,
  type: /* Tabs */1
};

var defaultAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  country: "",
  postal_code: ""
};

var defaultBillingDetails = {
  name: "",
  email: "",
  phone: "",
  address: defaultAddress
};

var defaultBusiness = {
  name: ""
};

var defaultDefaultValues = {
  billingDetails: defaultBillingDetails
};

var defaultshowAddress = {
  line1: /* Auto */0,
  line2: /* Auto */0,
  city: /* Auto */0,
  state: /* Auto */0,
  country: /* Auto */0,
  postal_code: /* Auto */0
};

var defaultNeverShowAddress = {
  line1: /* Never */1,
  line2: /* Never */1,
  city: /* Never */1,
  state: /* Never */1,
  country: /* Never */1,
  postal_code: /* Never */1
};

var defaultBilling_address = {
  TAG: /* JSONObject */1,
  _0: defaultshowAddress
};

var defaultBilling = {
  name: /* Auto */0,
  email: /* Auto */0,
  phone: /* Auto */0,
  address: defaultBilling_address
};

var defaultNeverBilling_address = {
  TAG: /* JSONObject */1,
  _0: defaultNeverShowAddress
};

var defaultNeverBilling = {
  name: /* Never */1,
  email: /* Never */1,
  phone: /* Never */1,
  address: defaultNeverBilling_address
};

var defaultTerms = {
  auBecsDebit: /* Auto */0,
  bancontact: /* Auto */0,
  card: /* Auto */0,
  ideal: /* Auto */0,
  sepaDebit: /* Auto */0,
  sofort: /* Auto */0,
  usBankAccount: /* Auto */0
};

var defaultFields = {
  billingDetails: {
    TAG: /* JSONObject */1,
    _0: defaultBilling
  }
};

var defaultStyle = {
  type_: [
    {
      TAG: /* ApplePay */0,
      _0: /* Default */0
    },
    {
      TAG: /* GooglePay */1,
      _0: /* Default */0
    },
    {
      TAG: /* Paypal */2,
      _0: /* Paypal */0
    }
  ],
  theme: /* Light */1,
  height: [
    {
      TAG: /* ApplePay */0,
      _0: 48
    },
    {
      TAG: /* GooglePay */1,
      _0: 48
    },
    {
      TAG: /* Paypal */2,
      _0: 48
    }
  ]
};

var defaultWallets = {
  walletReturnUrl: "",
  applePay: /* Auto */0,
  googlePay: /* Auto */0,
  style: defaultStyle
};

var defaultOptions_layout = {
  TAG: /* ObjectLayout */1,
  _0: defaultLayout
};

var defaultOptions_customMethodNames = [];

var defaultOptions = {
  defaultValues: defaultDefaultValues,
  layout: defaultOptions_layout,
  business: defaultBusiness,
  customerPaymentMethods: /* NoResult */1,
  paymentMethodOrder: undefined,
  disableSaveCards: true,
  fields: defaultFields,
  readOnly: false,
  terms: defaultTerms,
  wallets: defaultWallets,
  customMethodNames: defaultOptions_customMethodNames,
  branding: /* Never */1,
  payButtonStyle: defaultStyle,
  showCardFormByDefault: false
};

function getLayout(str, logger) {
  switch (str) {
    case "accordion" :
        return /* Accordion */0;
    case "tabs" :
        return /* Tabs */1;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "tabs",
              "accordion"
            ], "options.layout")(logger);
      return /* Tabs */1;
  }
}

function getAddress(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    var countryNames = [];
                    Country$OrcaPaymentPage.country.map(function (item) {
                          return countryNames.push(item.countryName);
                        });
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "line1",
                          "line2",
                          "city",
                          "state",
                          "country",
                          "postal_code"
                        ], json, "options.defaultValues.billingDetails.address", logger);
                    var country = Utils$OrcaPaymentPage.getWarningString(json, "country", "", logger);
                    if (country !== "") {
                      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(country, countryNames, "options.defaultValues.billingDetails.address.country")(logger);
                    }
                    return {
                            line1: Utils$OrcaPaymentPage.getWarningString(json, "line1", "", logger),
                            line2: Utils$OrcaPaymentPage.getWarningString(json, "line2", "", logger),
                            city: Utils$OrcaPaymentPage.getWarningString(json, "city", "", logger),
                            state: Utils$OrcaPaymentPage.getWarningString(json, "state", "", logger),
                            country: country,
                            postal_code: Utils$OrcaPaymentPage.getWarningString(json, "postal_code", "", logger)
                          };
                  })), defaultAddress);
}

function getBillingDetails(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "name",
                          "email",
                          "phone",
                          "address"
                        ], json, "options.defaultValues.billingDetails", logger);
                    return {
                            name: Utils$OrcaPaymentPage.getWarningString(json, "name", "", logger),
                            email: Utils$OrcaPaymentPage.getWarningString(json, "email", "", logger),
                            phone: Utils$OrcaPaymentPage.getWarningString(json, "phone", "", logger),
                            address: getAddress(json, "address", logger)
                          };
                  })), defaultBillingDetails);
}

function getDefaultValues(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(["billingDetails"], json, "options.defaultValues", logger);
                    return {
                            billingDetails: getBillingDetails(json, "billingDetails", logger)
                          };
                  })), defaultDefaultValues);
}

function getBusiness(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(["name"], json, "options.business", logger);
                    return {
                            name: Utils$OrcaPaymentPage.getWarningString(json, "name", "", logger)
                          };
                  })), defaultBusiness);
}

function getShowType(str, key, logger) {
  switch (str) {
    case "auto" :
        return /* Auto */0;
    case "never" :
        return /* Never */1;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "auto",
              "never"
            ], key)(logger);
      return /* Auto */0;
  }
}

function getApplePayType(str) {
  switch (str) {
    case "add-money" :
    case "addmoney" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Addmoney */6
              };
    case "buy" :
    case "buynow" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Buy */1
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Checkout */3
              };
    case "contribute" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Contribute */12
              };
    case "default" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Default */0
              };
    case "donate" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Donate */2
              };
    case "order" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Order */9
              };
    case "reload" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Reload */5
              };
    case "rent" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Rent */8
              };
    case "subscribe" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Subscribe */4
              };
    case "support" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Support */10
              };
    case "tip" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Tip */11
              };
    case "top-up" :
    case "topup" :
        return {
                TAG: /* ApplePay */0,
                _0: /* Topup */7
              };
    default:
      return {
              TAG: /* ApplePay */0,
              _0: /* Default */0
            };
  }
}

function getGooglePayType(str) {
  switch (str) {
    case "book" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Book */5
              };
    case "buy" :
    case "buynow" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Buy */1
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Checkout */3
              };
    case "donate" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Donate */2
              };
    case "order" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Order */7
              };
    case "pay" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Pay */6
              };
    case "default" :
    case "plain" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Default */0
              };
    case "subscribe" :
        return {
                TAG: /* GooglePay */1,
                _0: /* Subscribe */4
              };
    default:
      return {
              TAG: /* GooglePay */1,
              _0: /* Default */0
            };
  }
}

function getPayPalType(str) {
  switch (str) {
    case "buy" :
    case "buynow" :
        return {
                TAG: /* Paypal */2,
                _0: /* Buynow */2
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: /* Paypal */2,
                _0: /* Checkout */1
              };
    case "installment" :
        return {
                TAG: /* Paypal */2,
                _0: /* Installment */4
              };
    case "pay" :
        return {
                TAG: /* Paypal */2,
                _0: /* Pay */3
              };
    case "paypal" :
        return {
                TAG: /* Paypal */2,
                _0: /* Paypal */0
              };
    default:
      return {
              TAG: /* Paypal */2,
              _0: /* Paypal */0
            };
  }
}

function getTypeArray(str, logger) {
  var goodVals = [
    "checkout",
    "pay",
    "buy",
    "installment",
    "pay",
    "default",
    "book",
    "donate",
    "order",
    "addmoney",
    "topup",
    "rent",
    "subscribe",
    "reload",
    "support",
    "tip",
    "contribute"
  ];
  if (!goodVals.includes(str)) {
    ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, goodVals, "options.wallets.style.type")(logger);
  }
  return [
          getApplePayType(str),
          getGooglePayType(str),
          getPayPalType(str)
        ];
}

function getShowDetails(billingDetails, logger) {
  if (billingDetails.TAG === /* JSONString */0) {
    if (getShowType(billingDetails._0, "fields.billingDetails", logger) === /* Never */1) {
      return defaultNeverBilling;
    } else {
      return defaultBilling;
    }
  } else {
    return billingDetails._0;
  }
}

function getShowAddressDetails(billingDetails, logger) {
  if (billingDetails.TAG === /* JSONString */0) {
    if (getShowType(billingDetails._0, "fields.billingDetails", logger) === /* Never */1) {
      return defaultNeverShowAddress;
    } else {
      return defaultshowAddress;
    }
  }
  var str = billingDetails._0.address;
  if (str.TAG === /* JSONString */0) {
    if (getShowType(str._0, "fields.billingDetails.address", logger) === /* Never */1) {
      return defaultNeverShowAddress;
    } else {
      return defaultshowAddress;
    }
  } else {
    return str._0;
  }
}

function getShowTerms(str, key, logger) {
  switch (str) {
    case "always" :
        return /* Always */1;
    case "auto" :
        return /* Auto */0;
    case "never" :
        return /* Never */2;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "auto",
              "always",
              "never"
            ], key)(logger);
      return /* Auto */0;
  }
}

function getShowAddress(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            line1: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "line1", "auto", logger), "options.fields.address.line1", logger),
                            line2: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "line2", "auto", logger), "options.fields.address.line2", logger),
                            city: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "city", "auto", logger), "options.fields.address.city", logger),
                            state: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "state", "auto", logger), "options.fields.address.state", logger),
                            country: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "country", "auto", logger), "options.fields.address.country", logger),
                            postal_code: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "postal_code", "auto", logger), "options.fields.name.postal_code", logger)
                          };
                  })), defaultshowAddress);
}

function getDeatils(val, logger) {
  var str = Js_json.classify(val);
  if (typeof str === "number") {
    return {
            TAG: /* JSONString */0,
            _0: ""
          };
  }
  switch (str.TAG | 0) {
    case /* JSONString */0 :
        return {
                TAG: /* JSONString */0,
                _0: str._0
              };
    case /* JSONObject */2 :
        var json = str._0;
        return {
                TAG: /* JSONObject */1,
                _0: {
                  name: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "name", "auto", logger), "options.fields.name", logger),
                  email: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "email", "auto", logger), "options.fields.email", logger),
                  phone: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "phone", "auto", logger), "options.fields.phone", logger),
                  address: {
                    TAG: /* JSONObject */1,
                    _0: getShowAddress(json, "address", logger)
                  }
                }
              };
    default:
      return {
              TAG: /* JSONString */0,
              _0: ""
            };
  }
}

function getBilling(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, str), (function (json) {
                    return getDeatils(json, logger);
                  })), defaultFields.billingDetails);
}

function getFields(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            billingDetails: getBilling(json, "billingDetails", logger)
                          };
                  })), defaultFields);
}

function getLayoutValues(val, logger) {
  var str = Js_json.classify(val);
  if (typeof str === "number") {
    return {
            TAG: /* StringLayout */0,
            _0: /* Tabs */1
          };
  }
  switch (str.TAG | 0) {
    case /* JSONString */0 :
        return {
                TAG: /* StringLayout */0,
                _0: getLayout(str._0, logger)
              };
    case /* JSONObject */2 :
        var json = str._0;
        var layoutType = Utils$OrcaPaymentPage.getWarningString(json, "type", "tabs", logger);
        return {
                TAG: /* ObjectLayout */1,
                _0: (ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                        "defaultCollapsed",
                        "radios",
                        "spacedAccordionItems",
                        "type"
                      ], json, "options.layout", logger), {
                    defaultCollapsed: Utils$OrcaPaymentPage.getBoolWithWarning(json, "defaultCollapsed", false, logger),
                    radios: Utils$OrcaPaymentPage.getBoolWithWarning(json, "radios", false, logger),
                    spacedAccordionItems: Utils$OrcaPaymentPage.getBoolWithWarning(json, "spacedAccordionItems", false, logger),
                    maxAccordionItems: Utils$OrcaPaymentPage.getNumberWithWarning(json, "maxAccordionItems", logger, 4),
                    type: getLayout(layoutType, logger)
                  })
              };
    default:
      return {
              TAG: /* StringLayout */0,
              _0: /* Tabs */1
            };
  }
}

function getTerms(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "auBecsDebit",
                          "bancontact",
                          "card",
                          "ideal",
                          "sepaDebit",
                          "sofort",
                          "usBankAccount"
                        ], json, "options.terms", logger);
                    return {
                            auBecsDebit: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "auBecsDebit", "auto", logger), "options.terms.auBecsDebit", logger),
                            bancontact: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "bancontact", "auto", logger), "options.terms.bancontact", logger),
                            card: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "card", "auto", logger), "options.terms.card", logger),
                            ideal: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "ideal", "auto", logger), "options.terms.ideal", logger),
                            sepaDebit: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "sepaDebit", "auto", logger), "options.terms.sepaDebit", logger),
                            sofort: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "sofort", "auto", logger), "options.terms.sofort", logger),
                            usBankAccount: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "usBankAccount", "auto", logger), "options.terms.usBankAccount", logger)
                          };
                  })), defaultTerms);
}

function getApplePayHeight(val, logger) {
  if (val >= 45) {
    return {
            TAG: /* ApplePay */0,
            _0: val
          };
  } else {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[h>=45] - ApplePay. Value set to min")(logger);
    return {
            TAG: /* ApplePay */0,
            _0: 48
          };
  }
}

function getGooglePayHeight(val, logger) {
  if (val >= 48) {
    return {
            TAG: /* GooglePay */1,
            _0: val
          };
  } else {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[h>=48] - GooglePay. Value set to min")(logger);
    return {
            TAG: /* GooglePay */1,
            _0: 48
          };
  }
}

function getPaypalHeight(val, logger) {
  if (val < 25) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[25-55] - Paypal. Value set to min")(logger);
    return {
            TAG: /* Paypal */2,
            _0: 25
          };
  } else if (val > 55) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[25-55] - Paypal. Value set to max")(logger);
    return {
            TAG: /* Paypal */2,
            _0: 55
          };
  } else {
    return {
            TAG: /* Paypal */2,
            _0: val
          };
  }
}

function getTheme(str, logger) {
  switch (str) {
    case "dark" :
        return /* Dark */0;
    case "light" :
        return /* Light */1;
    case "outline" :
        return /* Outline */2;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "outline",
              "light",
              "dark"
            ], "options.styles.theme")(logger);
      return /* Dark */0;
  }
}

function getHeightArray(val, logger) {
  return [
          getApplePayHeight(val, logger),
          getGooglePayHeight(val, logger),
          getPaypalHeight(val, logger)
        ];
}

function getStyle(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "type",
                          "theme",
                          "height"
                        ], json, "options.wallets.style", logger);
                    return {
                            type_: getTypeArray(Utils$OrcaPaymentPage.getWarningString(json, "type", "", logger), logger),
                            theme: getTheme(Utils$OrcaPaymentPage.getWarningString(json, "theme", "", logger), logger),
                            height: getHeightArray(Utils$OrcaPaymentPage.getNumberWithWarning(json, "height", logger, 48), logger)
                          };
                  })), defaultStyle);
}

function getWallets(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "applePay",
                          "googlePay",
                          "style",
                          "walletReturnUrl"
                        ], json, "options.wallets", logger);
                    return {
                            walletReturnUrl: Utils$OrcaPaymentPage.getRequiredString(json, "walletReturnUrl", "", logger),
                            applePay: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "applePay", "auto", logger), "options.wallets.applePay", logger),
                            googlePay: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "googlePay", "auto", logger), "options.wallets.googlePay", logger),
                            style: getStyle(json, "style", logger)
                          };
                  })), defaultWallets);
}

function getLayout$1(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, str), (function (json) {
                    return getLayoutValues(json, logger);
                  })), {
              TAG: /* ObjectLayout */1,
              _0: defaultLayout
            });
}

function getCardDetails(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            scheme: Utils$OrcaPaymentPage.getString(json, "scheme", ""),
                            last4Digits: Utils$OrcaPaymentPage.getString(json, "last4_digits", ""),
                            expiryMonth: Utils$OrcaPaymentPage.getString(json, "expiry_month", ""),
                            expiryYear: Utils$OrcaPaymentPage.getString(json, "expiry_year", ""),
                            cardToken: Utils$OrcaPaymentPage.getString(json, "card_token", ""),
                            cardHolderName: Utils$OrcaPaymentPage.getString(json, "card_holder_name", "")
                          };
                  })), defaultCardDetails);
}

function createCustomerObjArr(dict) {
  var customerDict = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "customerPaymentMethods"), Js_json.decodeObject), {});
  var customerArr = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(customerDict, "customer_payment_methods"), Js_json.decodeArray), []);
  var customerPaymentMethods = Belt_Array.keepMap(customerArr, Js_json.decodeObject).map(function (json) {
        return {
                paymentToken: Utils$OrcaPaymentPage.getString(json, "payment_token", ""),
                customerId: Utils$OrcaPaymentPage.getString(json, "customer_id", ""),
                paymentMethod: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                paymentMethodIssuer: Utils$OrcaPaymentPage.getString(json, "payment_method_issuer", ""),
                card: getCardDetails(json, "card")
              };
      });
  return /* LoadedSavedCards */{
          _0: customerPaymentMethods
        };
}

function getCustomerMethods(dict, str) {
  var customerArr = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []);
  if (customerArr.length === 0) {
    return /* LoadingSavedCards */0;
  }
  var customerPaymentMethods = Belt_Array.keepMap(customerArr, Js_json.decodeObject).map(function (json) {
        return {
                paymentToken: Utils$OrcaPaymentPage.getString(json, "payment_token", ""),
                customerId: Utils$OrcaPaymentPage.getString(json, "customer_id", ""),
                paymentMethod: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                paymentMethodIssuer: Utils$OrcaPaymentPage.getString(json, "payment_method_issuer", ""),
                card: getCardDetails(json, "card")
              };
      });
  return /* LoadedSavedCards */{
          _0: customerPaymentMethods
        };
}

function getCustomMethodNames(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      paymentMethodName: Utils$OrcaPaymentPage.getString(json, "paymentMethodName", ""),
                      aliasName: Utils$OrcaPaymentPage.getString(json, "aliasName", "")
                    };
            });
}

function itemToObjMapper(dict, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "defaultValues",
        "business",
        "layout",
        "paymentMethodOrder",
        "customerPaymentMethods",
        "fields",
        "readOnly",
        "terms",
        "wallets",
        "showCardFormByDefault",
        "disableSaveCards"
      ], dict, "options", logger);
  return {
          defaultValues: getDefaultValues(dict, "defaultValues", logger),
          layout: getLayout$1(dict, "layout", logger),
          business: getBusiness(dict, "business", logger),
          customerPaymentMethods: getCustomerMethods(dict, "customerPaymentMethods"),
          paymentMethodOrder: Utils$OrcaPaymentPage.getOptionalStrArray(dict, "paymentMethodOrder"),
          disableSaveCards: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "disableSaveCards", true, logger),
          fields: getFields(dict, "fields", logger),
          readOnly: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "readOnly", false, logger),
          terms: getTerms(dict, "terms", logger),
          wallets: getWallets(dict, "wallets", logger),
          customMethodNames: getCustomMethodNames(dict, "customMethodNames"),
          branding: getShowType(Utils$OrcaPaymentPage.getWarningString(dict, "branding", "never", logger), "options.branding", logger),
          payButtonStyle: getStyle(dict, "payButtonStyle", logger),
          showCardFormByDefault: Utils$OrcaPaymentPage.getBool(dict, "showCardFormByDefault", false)
        };
}

function getIsAllStoredCardsHaveName(savedCards) {
  return savedCards.filter(function (savedCard) {
              var match = savedCard.card.cardHolderName;
              if (match !== undefined && match !== "") {
                return true;
              } else {
                return false;
              }
            }).length === savedCards.length;
}

export {
  defaultCardDetails ,
  defaultCustomerMethods ,
  defaultLayout ,
  defaultAddress ,
  defaultBillingDetails ,
  defaultBusiness ,
  defaultDefaultValues ,
  defaultshowAddress ,
  defaultNeverShowAddress ,
  defaultBilling ,
  defaultNeverBilling ,
  defaultTerms ,
  defaultFields ,
  defaultStyle ,
  defaultWallets ,
  defaultOptions ,
  getAddress ,
  getBillingDetails ,
  getDefaultValues ,
  getBusiness ,
  getShowType ,
  getApplePayType ,
  getGooglePayType ,
  getPayPalType ,
  getTypeArray ,
  getShowDetails ,
  getShowAddressDetails ,
  getShowTerms ,
  getShowAddress ,
  getDeatils ,
  getBilling ,
  getFields ,
  getLayoutValues ,
  getTerms ,
  getApplePayHeight ,
  getGooglePayHeight ,
  getPaypalHeight ,
  getTheme ,
  getHeightArray ,
  getStyle ,
  getWallets ,
  getLayout$1 as getLayout,
  getCardDetails ,
  createCustomerObjArr ,
  getCustomerMethods ,
  getCustomMethodNames ,
  itemToObjMapper ,
  getIsAllStoredCardsHaveName ,
}
/* Utils-OrcaPaymentPage Not a pure module */
