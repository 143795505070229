// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Icon(props) {
  var shouldMirrorIcon = props.shouldMirrorIcon;
  var style = props.style;
  var iconType = props.iconType;
  var className = props.className;
  var width = props.width;
  var size = props.size;
  var size$1 = size !== undefined ? size : 20;
  var width$1 = width !== undefined ? width : size$1;
  var iconType$1 = iconType !== undefined ? iconType : "orca";
  var style$1 = style !== undefined ? style : ({});
  var shouldMirrorIcon$1 = shouldMirrorIcon !== undefined ? shouldMirrorIcon : false;
  var otherClasses = className !== undefined ? className : "";
  return React.createElement("svg", {
              className: "fill-current " + otherClasses + "",
              style: style$1,
              height: String(size$1) + "px",
              width: String(width$1) + "px",
              onClick: props.onClick,
              transform: shouldMirrorIcon$1 ? "scale(-1,1)" : ""
            }, React.createElement("use", {
                  xlinkHref: "" + publicPath + "/icons/" + iconType$1 + ".svg#" + props.name + ""
                }));
}

var make = Icon;

export {
  make ,
}
/* react Not a pure module */
