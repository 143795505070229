// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Payment$OrcaPaymentPage from "./Payment.bs.js";
import * as CardUtils$OrcaPaymentPage from "./CardUtils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "./orca-log-catcher/OrcaLogger.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as PaymentLoader$OrcaPaymentPage from "./Components/PaymentLoader.bs.js";
import * as QRCodeDisplay$OrcaPaymentPage from "./Payments/QRCodeDisplay.bs.js";
import * as PreMountLoader$OrcaPaymentPage from "./Payments/PreMountLoader.bs.js";
import * as LoaderController$OrcaPaymentPage from "./LoaderController.bs.js";
import * as BankTransfersPopup$OrcaPaymentPage from "./Payments/BankTransfersPopup.bs.js";
import * as FullScreenDivDriver$OrcaPaymentPage from "./Components/FullScreenDivDriver.bs.js";

function App(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoggerState = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var paymentMode = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "componentName");
  var fullscreenMode = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "fullscreenType");
  var logger = React.useMemo((function () {
          return OrcaLogger$OrcaPaymentPage.make(undefined, undefined, undefined, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          setLoggerState(function (param) {
                return logger;
              });
        }), [logger]);
  var renderFullscreen;
  switch (fullscreenMode) {
    case "fullscreen" :
        renderFullscreen = React.createElement("div", {
              id: "fullscreen"
            }, React.createElement(FullScreenDivDriver$OrcaPaymentPage.make, {}));
        break;
    case "paymentloader" :
        renderFullscreen = React.createElement(PaymentLoader$OrcaPaymentPage.make, {});
        break;
    case "preMountLoader" :
        var clientSecret = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "clientSecret");
        var sessionId = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "sessionId");
        var publishableKey = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "publishableKey");
        var endpoint = decodeURIComponent(CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "endpoint"));
        renderFullscreen = React.createElement(PreMountLoader$OrcaPaymentPage.make, {
              sessionId: sessionId,
              publishableKey: publishableKey,
              clientSecret: clientSecret,
              endpoint: endpoint
            });
        break;
    case "qrData" :
        renderFullscreen = React.createElement(QRCodeDisplay$OrcaPaymentPage.make, {});
        break;
    case "achBankTransfer" :
    case "bacsBankTransfer" :
    case "sepaBankTransfer" :
        renderFullscreen = React.createElement(BankTransfersPopup$OrcaPaymentPage.make, {
              transferType: fullscreenMode
            });
        break;
    default:
      renderFullscreen = React.createElement(LoaderController$OrcaPaymentPage.make, {
            children: React.createElement(Payment$OrcaPaymentPage.make, {
                  paymentMode: paymentMode,
                  integrateError: match[0],
                  logger: logger
                }),
            paymentMode: paymentMode,
            setIntegrateErrorError: match[1],
            logger: logger
          });
  }
  return React.createElement(React.Fragment, undefined, renderFullscreen);
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
