// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultError = {
  type_: "server_error",
  code: "",
  message: "Something went wrong"
};

function getError(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            type_: Utils$OrcaPaymentPage.getString(json, "type", ""),
                            code: Utils$OrcaPaymentPage.getString(json, "code", ""),
                            message: Utils$OrcaPaymentPage.getString(json, "message", "")
                          };
                  })), defaultError);
}

function itemToObjMapper(dict) {
  return {
          error: getError(dict, "error")
        };
}

export {
  defaultError ,
  getError ,
  itemToObjMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
