// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "./libraries/Recoil.bs.js";
import * as Window$OrcaPaymentPage from "./Window.bs.js";
import * as CardTheme$OrcaPaymentPage from "./CardTheme.bs.js";
import * as CardUtils$OrcaPaymentPage from "./CardUtils.bs.js";
import * as OrcaUtils$OrcaPaymentPage from "./orca-loader/OrcaUtils.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "./Utilities/ApiEndpoint.bs.js";
import * as CommonHooks$OrcaPaymentPage from "./Hooks/CommonHooks.bs.js";
import * as ElementType$OrcaPaymentPage from "./Types/ElementType.bs.js";
import * as PaymentType$OrcaPaymentPage from "./Types/PaymentType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as DefaultTheme$OrcaPaymentPage from "./DefaultTheme.bs.js";

function LoaderController(props) {
  var logger = props.logger;
  var setIntegrateErrorError = props.setIntegrateErrorError;
  var paymentMode = props.paymentMode;
  var match = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.configAtom);
  var setConfig = match[1];
  var match$1 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.keys);
  var setKeys = match$1[1];
  var keys = match$1[0];
  var match$2 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.list);
  var setList = match$2[1];
  var paymentlist = match$2[0];
  var match$3 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.sessions);
  var setSessions = match$3[1];
  var match$4 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.elementOptions);
  var setOptions = match$4[1];
  var options = match$4[0];
  var match$5 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var setOptionsPayment = match$5[1];
  var optionsPayment = match$5[0];
  var setSessionId = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.sessionId);
  var setBlockConfirm = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isConfirmBlocked);
  var setSwitchToCustomPod = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.switchToCustomPod);
  var setIsGooglePayReady = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isGooglePayReady);
  var setIsApplePayReady = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isApplePayReady);
  var match$6 = React.useState(function () {
        return 0.0;
      });
  var showCardFormByDefault = optionsPayment.showCardFormByDefault;
  var paymentMethodOrder = optionsPayment.paymentMethodOrder;
  var setDivH = match$6[1];
  var divH = match$6[0];
  var divRef = React.useRef(null);
  var iframeId = keys.iframeId;
  var config = match[0].config;
  var handlePostMessage = function (data) {
    Utils$OrcaPaymentPage.handlePostMessage(keys.parentURL, data);
  };
  var setUserFullName = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userFullName, "fullName", logger);
  var setUserEmail = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userEmailAddress, "email", logger);
  var setUserAddressline1 = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline1, "line1", logger);
  var setUserAddressline2 = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline2, "line2", logger);
  var setUserAddressCity = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCity, "city", logger);
  var setUserAddressPincode = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressPincode, "pin", logger);
  var setUserAddressState = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressState, "state", logger);
  var setUserAddressCountry = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCountry, "country", logger);
  var match$7 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.userCountry);
  var setCountry = match$7[1];
  var updateOptions = function (dict) {
    var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
    var match = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
    if (match !== 1) {
      if (match >= 5) {
        return ;
      } else {
        return setOptions(function (param) {
                    return ElementType$OrcaPaymentPage.itemToObjMapper(optionsDict, logger);
                  });
      }
    }
    var paymentOptions = PaymentType$OrcaPaymentPage.itemToObjMapper(optionsDict, logger);
    setOptionsPayment(function (param) {
          return paymentOptions;
        });
    [
        [
          paymentOptions.defaultValues.billingDetails.name,
          setUserFullName
        ],
        [
          paymentOptions.defaultValues.billingDetails.email,
          setUserEmail
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.line1,
          setUserAddressline1
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.line2,
          setUserAddressline2
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.city,
          setUserAddressCity
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.postal_code,
          setUserAddressPincode
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.state,
          setUserAddressState
        ],
        [
          paymentOptions.defaultValues.billingDetails.address.country,
          setUserAddressCountry
        ]
      ].forEach(function (val) {
          var value = val[0];
          if (value !== "") {
            return val[1](function (prev) {
                        return {
                                value: value,
                                isValid: prev.isValid,
                                errorString: prev.errorString
                              };
                      });
          }
          
        });
    if (paymentOptions.defaultValues.billingDetails.address.country === "") {
      var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);
      setUserAddressCountry(function (prev) {
            return {
                    value: clientCountry.countryName,
                    isValid: prev.isValid,
                    errorString: prev.errorString
                  };
          });
      return setCountry(function (param) {
                  return clientCountry.countryName;
                });
    }
    setUserAddressCountry(function (prev) {
          return {
                  value: paymentOptions.defaultValues.billingDetails.address.country,
                  isValid: prev.isValid,
                  errorString: prev.errorString
                };
        });
    setCountry(function (param) {
          return paymentOptions.defaultValues.billingDetails.address.country;
        });
  };
  var setConfigs = function (dict, themeValues) {
    var paymentOptions = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
    var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
    var $$default = themeValues.default;
    var defaultRules = themeValues.defaultRules;
    var config = CardTheme$OrcaPaymentPage.itemToObjMapper(paymentOptions, $$default, defaultRules, logger);
    var localeString = Utils$OrcaPaymentPage.getWarningString(optionsDict, "locale", "", logger);
    var optionsAppearance = CardTheme$OrcaPaymentPage.getAppearance("appearance", optionsDict, $$default, defaultRules, logger);
    var appearance = Caml_obj.equal(optionsAppearance, CardTheme$OrcaPaymentPage.defaultAppearance) ? config.appearance : optionsAppearance;
    setConfig(function (param) {
          return {
                  config: {
                    appearance: appearance,
                    locale: config.locale,
                    clientSecret: config.clientSecret,
                    fonts: config.fonts,
                    loader: config.loader
                  },
                  themeObj: appearance.variables,
                  localeString: localeString === "" ? CardTheme$OrcaPaymentPage.getLocaleObject(config.locale) : CardTheme$OrcaPaymentPage.getLocaleObject(localeString),
                  showLoader: config.loader === /* Auto */0 || config.loader === /* Always */1
                };
        });
  };
  React.useEffect((function () {
          handlePostMessage([[
                  "iframeMounted",
                  true
                ]]);
          handlePostMessage([[
                  "applePayMounted",
                  true
                ]]);
          Curry._1(logger.setLogInitiated, undefined);
          window.addEventListener("click", (function (ev) {
                  Utils$OrcaPaymentPage.handleOnClickPostMessage(keys.parentURL, ev);
                }));
          return (function (param) {
                    window.removeEventListener("click", (function (ev) {
                            Utils$OrcaPaymentPage.handleOnClickPostMessage(keys.parentURL, ev);
                          }));
                  });
        }), []);
  React.useEffect((function () {
          if (typeof paymentlist === "number") {
            if (paymentlist === /* Loading */0) {
              handlePostMessage([[
                      "ready",
                      false
                    ]]);
            }
            
          } else if (paymentlist.TAG === /* Loaded */0) {
            handlePostMessage([[
                    "ready",
                    true
                  ]]);
          } else {
            handlePostMessage([[
                    "ready",
                    false
                  ]]);
          }
        }), [paymentlist]);
  React.useEffect((function () {
          CardUtils$OrcaPaymentPage.genreateFontsLink(config.fonts);
          var dict = Utils$OrcaPaymentPage.getDictFromJson(config.appearance.rules);
          if (Js_dict.entries(dict).length > 0) {
            Utils$OrcaPaymentPage.generateStyleSheet("", dict, "themestyle");
          }
          var match = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
          if (match !== 1) {
            var styleClass = [
              [
                "input-base",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.base)
              ],
              [
                "input-complete",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.complete)
              ],
              [
                "input-invalid",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.invalid)
              ],
              [
                "input-empty",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.empty)
              ]
            ];
            styleClass.map(function (item) {
                  var dict = item[1];
                  if (Js_dict.entries(dict).length > 0) {
                    Utils$OrcaPaymentPage.generateStyleSheet(item[0], dict, "widgetstyle");
                    return ;
                  }
                  
                });
          }
          
        }), [config]);
  React.useEffect((function () {
          var handleFun = function (ev) {
            var json;
            try {
              json = JSON.parse(ev.data);
            }
            catch (exn){
              json = {};
            }
            try {
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentElementCreate")) {
                if (Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "paymentElementCreate"), Js_json.decodeBoolean), false)) {
                  if (Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "otherElements"), Js_json.decodeBoolean), false)) {
                    updateOptions(dict);
                  } else {
                    var sdkSessionId = Utils$OrcaPaymentPage.getString(dict, "sdkSessionId", "no-element");
                    Curry._1(logger.setSessionId, sdkSessionId);
                    if (Window$OrcaPaymentPage.isInteg) {
                      setBlockConfirm(function (param) {
                            return Utils$OrcaPaymentPage.getBool(dict, "AOrcaBBlockPConfirm", false);
                          });
                      setSwitchToCustomPod(function (param) {
                            return Utils$OrcaPaymentPage.getBool(dict, "switchToCustomPodABP", false);
                          });
                    }
                    updateOptions(dict);
                    setSessionId(function (param) {
                          return sdkSessionId;
                        });
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "publishableKey")) {
                      Curry._1(logger.setMerchantId, Utils$OrcaPaymentPage.getString(dict, "publishableKey", ""));
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "endpoint")) {
                      ApiEndpoint$OrcaPaymentPage.setApiEndPoint(Utils$OrcaPaymentPage.getString(dict, "endpoint", ""));
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "analyticsMetadata")) {
                      Curry._1(logger.setMetadata, Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "analyticsMetadata"));
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentOptions")) {
                      var paymentOptions = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
                      var clientSecret = Utils$OrcaPaymentPage.getWarningString(paymentOptions, "clientSecret", "", logger);
                      setKeys(function (prev) {
                            return {
                                    clientSecret: clientSecret,
                                    publishableKey: prev.publishableKey,
                                    iframeId: prev.iframeId,
                                    parentURL: prev.parentURL,
                                    sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                                  };
                          });
                      Curry._1(logger.setClientSecret, clientSecret);
                      var promise = OrcaUtils$OrcaPaymentPage.getThemePromise(paymentOptions);
                      if (promise !== undefined) {
                        Caml_option.valFromOption(promise).then(function (res) {
                              setConfigs(dict, res);
                              return Promise.resolve(undefined);
                            });
                      } else {
                        setConfigs(dict, {
                              default: DefaultTheme$OrcaPaymentPage.$$default,
                              defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                            });
                      }
                    }
                    Curry._8(logger.setLogInfo, "paymentElementCreate", undefined, /* APP_RENDERED */0, undefined, undefined, undefined, undefined, undefined);
                    [
                        [
                          "iframeId",
                          "no-element"
                        ],
                        [
                          "publishableKey",
                          ""
                        ],
                        [
                          "parentURL",
                          "*"
                        ],
                        [
                          "sdkHandleConfirmPayment",
                          false
                        ]
                      ].forEach(function (keyPair) {
                          CommonHooks$OrcaPaymentPage.updateKeys(dict, keyPair, setKeys);
                        });
                    Curry._8(logger.setLogInfo, "", undefined, /* PAYMENT_OPTIONS_PROVIDED */27, undefined, undefined, undefined, undefined, undefined);
                  }
                } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentOptions")) {
                  var paymentOptions$1 = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
                  var clientSecret$1 = Utils$OrcaPaymentPage.getWarningString(paymentOptions$1, "clientSecret", "", logger);
                  setKeys(function (prev) {
                        return {
                                clientSecret: clientSecret$1,
                                publishableKey: prev.publishableKey,
                                iframeId: prev.iframeId,
                                parentURL: prev.parentURL,
                                sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                              };
                      });
                  Curry._1(logger.setClientSecret, clientSecret$1);
                  var promise$1 = OrcaUtils$OrcaPaymentPage.getThemePromise(paymentOptions$1);
                  if (promise$1 !== undefined) {
                    Caml_option.valFromOption(promise$1).then(function (res) {
                          setConfigs(dict, res);
                          return Promise.resolve(undefined);
                        });
                  } else {
                    setConfigs(dict, {
                          default: DefaultTheme$OrcaPaymentPage.$$default,
                          defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                        });
                  }
                }
                
              } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentElementsUpdate")) {
                updateOptions(dict);
              } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "ElementsUpdate")) {
                var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
                var clientSecret$2 = Js_dict.get(dict, "clientSecret");
                if (clientSecret$2 !== undefined) {
                  var val = Caml_option.valFromOption(clientSecret$2);
                  setKeys(function (prev) {
                        return {
                                clientSecret: Belt_Option.getWithDefault(Js_json.decodeString(val), ""),
                                publishableKey: prev.publishableKey,
                                iframeId: prev.iframeId,
                                parentURL: prev.parentURL,
                                sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                              };
                      });
                  setConfig(function (prev) {
                        var init = prev.config;
                        return {
                                config: {
                                  appearance: init.appearance,
                                  locale: init.locale,
                                  clientSecret: Belt_Option.getWithDefault(Js_json.decodeString(val), ""),
                                  fonts: init.fonts,
                                  loader: init.loader
                                },
                                themeObj: prev.themeObj,
                                localeString: prev.localeString,
                                showLoader: prev.showLoader
                              };
                      });
                }
                var promise$2 = OrcaUtils$OrcaPaymentPage.getThemePromise(optionsDict);
                if (promise$2 !== undefined) {
                  Caml_option.valFromOption(promise$2).then(function (res) {
                        setConfigs(dict, res);
                        return Promise.resolve(undefined);
                      });
                } else {
                  setConfigs(dict, {
                        default: DefaultTheme$OrcaPaymentPage.$$default,
                        defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                      });
                }
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "sessions")) {
                setSessions(function (param) {
                      return {
                              TAG: /* Loaded */0,
                              _0: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "sessions")
                            };
                    });
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "isReadyToPay")) {
                setIsGooglePayReady(function (param) {
                      return Belt_Option.getWithDefault(Js_json.decodeBoolean(Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "isReadyToPay")), false);
                    });
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentMethodList")) {
                var list = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "paymentMethodList");
                if (Caml_obj.equal(list, {})) {
                  setList(function (param) {
                        return {
                                TAG: /* LoadError */1,
                                _0: {}
                              };
                      });
                } else {
                  var err = Js_dict.get(Utils$OrcaPaymentPage.getDictFromJson(list), "error");
                  if (err !== undefined) {
                    var err$1 = Caml_option.valFromOption(err);
                    setList(function (param) {
                          return {
                                  TAG: /* LoadError */1,
                                  _0: err$1
                                };
                        });
                  } else {
                    setList(function (param) {
                          return {
                                  TAG: /* Loaded */0,
                                  _0: list
                                };
                        });
                  }
                }
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "customerPaymentMethods")) {
                var customerPaymentMethods = PaymentType$OrcaPaymentPage.createCustomerObjArr(dict);
                setOptionsPayment(function (prev) {
                      return {
                              defaultValues: prev.defaultValues,
                              layout: prev.layout,
                              business: prev.business,
                              customerPaymentMethods: customerPaymentMethods,
                              paymentMethodOrder: prev.paymentMethodOrder,
                              disableSaveCards: prev.disableSaveCards,
                              fields: prev.fields,
                              readOnly: prev.readOnly,
                              terms: prev.terms,
                              wallets: prev.wallets,
                              customMethodNames: prev.customMethodNames,
                              branding: prev.branding,
                              payButtonStyle: prev.payButtonStyle,
                              showCardFormByDefault: prev.showCardFormByDefault
                            };
                    });
              }
              if (Belt_Option.isSome(Js_dict.get(dict, "applePayCanMakePayments"))) {
                setIsApplePayReady(function (param) {
                      return true;
                    });
              }
              if (Belt_Option.isSome(Js_dict.get(dict, "applePaySessionObjNotPresent"))) {
                return setIsApplePayReady(function (prev) {
                            return false;
                          });
              } else {
                return ;
              }
            }
            catch (exn$1){
              return Curry._1(setIntegrateErrorError, (function (param) {
                            return true;
                          }));
            }
          };
          return Utils$OrcaPaymentPage.handleMessage(handleFun, "Error in parsing sent Data");
        }), [
        showCardFormByDefault,
        paymentMethodOrder
      ]);
  React.useEffect((function () {
          if (typeof paymentlist === "number") {
            setList(function (param) {
                  if (showCardFormByDefault && Utils$OrcaPaymentPage.checkPriorityList(paymentMethodOrder)) {
                    return /* SemiLoaded */1;
                  } else {
                    return /* Loading */0;
                  }
                });
          }
          
        }), [paymentlist]);
  var observer = new ResizeObserver((function (entries) {
          entries.map(function (item) {
                Curry._1(setDivH, (function (param) {
                        return item.contentRect.height;
                      }));
              });
        }));
  var r = divRef.current;
  if (!(r == null)) {
    observer.observe(r);
  }
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                [
                  "iframeHeight",
                  divH + 1.0
                ],
                [
                  "iframeId",
                  iframeId
                ]
              ]);
        }), [
        divH,
        iframeId
      ]);
  return React.createElement("div", {
              ref: Caml_option.some(divRef)
            }, props.children);
}

var make = LoaderController;

export {
  make ,
}
/* react Not a pure module */
