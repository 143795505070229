// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Client from "react-dom/client";
import * as App$OrcaPaymentPage from "./App.bs.js";
import * as Sentry$OrcaPaymentPage from "./orca-log-catcher/Sentry.bs.js";
import * as ErrorBoundary$OrcaPaymentPage from "./orca-log-catcher/ErrorBoundary.bs.js";

require("tailwindcss/tailwind.css")
;

Sentry$OrcaPaymentPage.initiateSentry(sentryDSN);

var container = document.querySelector("#app");

var app;

if (container == null) {
  app = undefined;
} else {
  var root = Client.createRoot(container);
  root.render(React.createElement("div", {
            className: "h-auto flex flex-col "
          }, React.createElement("div", {
                className: "h-auto flex flex-col"
              }, React.createElement(Recoil.RecoilRoot, {
                    children: React.createElement(ErrorBoundary$OrcaPaymentPage.make, {
                          children: React.createElement(App$OrcaPaymentPage.make, {}),
                          level: /* Top */0
                        })
                  }))));
  app = undefined;
}

export {
  app ,
}
/*  Not a pure module */
