// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PaymentElementShimmer$Shimmer(props) {
  var classname = props.classname;
  var classname$1 = classname !== undefined ? classname : "";
  return React.createElement("div", {
              className: "relative\n      " + classname$1 + "\n    before:absolute before:inset-0\n    before:-translate-x-full\n    before:animate-[shimmer_1s_infinite]\n    before:bg-gradient-to-r\n    before:px-1\n    before: rounded\n    before:from-transparent before:via-slate-200 before:to-transparent overflow-hidden w-full"
            }, props.children);
}

var Shimmer = {
  make: PaymentElementShimmer$Shimmer
};

function PaymentElementShimmer(props) {
  return React.createElement("div", {
              className: "flex flex-col gap-4"
            }, React.createElement(PaymentElementShimmer$Shimmer, {
                  children: React.createElement("div", {
                        className: "animate-pulse w-full h-12 rounded bg-slate-200 "
                      }, React.createElement("div", {
                            className: "flex flex-row  my-auto"
                          }, React.createElement("div", {
                                className: " w-10 h-5 rounded-full m-3 bg-white bg-opacity-70 "
                              }), React.createElement("div", {
                                className: " my-auto w-24 h-2 rounded m-3 bg-white bg-opacity-70 "
                              })))
                }), React.createElement(PaymentElementShimmer$Shimmer, {
                  children: React.createElement("div", {
                        className: "animate-pulse w-full h-12 rounded bg-slate-200 "
                      }, React.createElement("div", {
                            className: "flex flex-row  my-auto"
                          }, React.createElement("div", {
                                className: " w-10 h-5 rounded-full m-3 bg-white bg-opacity-70 "
                              }), React.createElement("div", {
                                className: " my-auto w-24 h-2 rounded m-3 bg-white bg-opacity-70 "
                              })))
                }), React.createElement("div", {
                  className: "flex flex-row gap-4 w-full"
                }, React.createElement(PaymentElementShimmer$Shimmer, {
                      children: React.createElement("div", {
                            className: "animate-pulse w-auto  h-12 rounded  bg-slate-200"
                          }, React.createElement("div", {
                                className: "flex flex-row  my-auto"
                              }, React.createElement("div", {
                                    className: " w-10 h-5 rounded-full m-3 bg-white bg-opacity-70 "
                                  }), React.createElement("div", {
                                    className: " my-auto w-24 h-2 rounded m-3 bg-white bg-opacity-70 "
                                  })))
                    }), React.createElement(PaymentElementShimmer$Shimmer, {
                      children: React.createElement("div", {
                            className: "animate-pulse w-auto h-12 rounded  bg-slate-200"
                          }, React.createElement("div", {
                                className: "flex flex-row  my-auto"
                              }, React.createElement("div", {
                                    className: " w-10 h-5 rounded-full m-3 bg-white bg-opacity-70 "
                                  }), React.createElement("div", {
                                    className: " my-auto w-24 h-2 rounded m-3 bg-white bg-opacity-70 "
                                  })))
                    })));
}

var make = PaymentElementShimmer;

export {
  Shimmer ,
  make ,
}
/* react Not a pure module */
