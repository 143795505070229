// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$OrcaPaymentPage from "./Icon.bs.js";

function icon(sizeOpt, widthOpt, name) {
  var size = sizeOpt !== undefined ? sizeOpt : 22;
  var width = widthOpt !== undefined ? widthOpt : size;
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: name,
              size: size,
              width: width
            });
}

var details = [
  {
    type_: "card",
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card"
  },
  {
    type_: "crypto_currency",
    icon: Caml_option.some(icon(19, undefined, "crypto")),
    displayName: "Crypto"
  },
  {
    type_: "klarna",
    icon: Caml_option.some(icon(19, undefined, "klarna")),
    displayName: "Klarna"
  },
  {
    type_: "afterpay_clearpay",
    icon: Caml_option.some(icon(19, undefined, "afterpay")),
    displayName: "After Pay"
  },
  {
    type_: "affirm",
    icon: Caml_option.some(icon(19, undefined, "affirm")),
    displayName: "Affirm"
  },
  {
    type_: "sofort",
    icon: Caml_option.some(icon(19, undefined, "sofort")),
    displayName: "Sofort"
  },
  {
    type_: "ach_transfer",
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Transfer"
  },
  {
    type_: "sepa_transfer",
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "Sepa Transfer"
  },
  {
    type_: "bacs_transfer",
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "Bacs Transfer"
  },
  {
    type_: "giropay",
    icon: Caml_option.some(icon(19, 25, "giropay")),
    displayName: "GiroPay"
  },
  {
    type_: "eps",
    icon: Caml_option.some(icon(19, 25, "eps")),
    displayName: "EPS"
  },
  {
    type_: "ideal",
    icon: Caml_option.some(icon(19, 25, "ideal")),
    displayName: "iDEAL"
  },
  {
    type_: "ban_connect",
    icon: undefined,
    displayName: "Ban Connect"
  },
  {
    type_: "ach_debit",
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Debit"
  },
  {
    type_: "sepa_debit",
    icon: Caml_option.some(icon(19, 25, "sepa")),
    displayName: "SEPA Debit"
  },
  {
    type_: "bacs_debit",
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BACS Debit"
  },
  {
    type_: "becs_debit",
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BECS Debit"
  }
];

var defaultPaymentDetails = {
  type_: "",
  icon: undefined,
  displayName: ""
};

export {
  defaultPaymentDetails ,
  icon ,
  details ,
}
/* details Not a pure module */
