// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getLogtype(val) {
  switch (val) {
    case "err" :
        return /* Err */4;
    case "method" :
        return /* Method */3;
    case "no_response" :
        return /* NoResponse */2;
    case "request" :
        return /* Request */0;
    case "response" :
        return /* Response */1;
    default:
      return /* Err */4;
  }
}

function logApi(eventName, statusCodeOpt, dataOpt, type_, urlOpt, paymentMethodOpt, resultOpt, optLogger, logTypeOpt, logCategoryOpt, param) {
  var statusCode = statusCodeOpt !== undefined ? statusCodeOpt : "";
  var data = dataOpt !== undefined ? Caml_option.valFromOption(dataOpt) : ({});
  var url = urlOpt !== undefined ? urlOpt : "";
  var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
  var result = resultOpt !== undefined ? Caml_option.valFromOption(resultOpt) : ({});
  var logType = logTypeOpt !== undefined ? logTypeOpt : /* INFO */1;
  var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : /* API */0;
  var logtype = getLogtype(type_);
  var match;
  switch (logtype) {
    case /* Request */0 :
        match = [
          [[
              "url",
              url
            ]],
          []
        ];
        break;
    case /* Response */1 :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case /* NoResponse */2 :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              "504"
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case /* Method */3 :
        match = [
          [[
              "method",
              paymentMethod
            ]],
          [[
              "result",
              result
            ]]
        ];
        break;
    case /* Err */4 :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    
  }
  if (optLogger !== undefined) {
    return Curry.app(optLogger.setLogApi, [
                {
                  TAG: /* ArrayType */0,
                  _0: match[0]
                },
                {
                  TAG: /* ArrayType */0,
                  _0: match[1]
                },
                eventName,
                undefined,
                logType,
                logCategory,
                undefined,
                type_,
                undefined
              ]);
  }
  
}

function logInputChangeInfo(text, logger) {
  Curry._8(logger.setLogInfo, JSON.stringify(Js_dict.fromArray([[
                  "inputChange",
                  text
                ]])), undefined, /* INPUT_FIELD_CHANGED */6, undefined, undefined, undefined, undefined, undefined);
}

function handleLogging(optLogger, value, internalMetadataOpt, eventName, paymentMethod, logTypeOpt, param) {
  var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
  var logType = logTypeOpt !== undefined ? logTypeOpt : /* INFO */1;
  if (optLogger !== undefined) {
    return Curry._8(optLogger.setLogInfo, value, internalMetadata, eventName, undefined, logType, undefined, paymentMethod, undefined);
  }
  
}

export {
  getLogtype ,
  logApi ,
  logInputChangeInfo ,
  handleLogging ,
}
/* No side effect */
