// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Loader$OrcaPaymentPage from "./Loader.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function close(setOpenModal) {
  Curry._1(setOpenModal, (function (param) {
          return false;
        }));
  setTimeout((function (param) {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "fullscreen",
                  false
                ]]);
        }), 450);
}

function Modal(props) {
  var openModal = props.openModal;
  var setOpenModal = props.setOpenModal;
  var testMode = props.testMode;
  var showClose = props.showClose;
  var loader = props.loader;
  var closeCallback = props.closeCallback;
  var loader$1 = loader !== undefined ? loader : false;
  var showClose$1 = showClose !== undefined ? showClose : true;
  var testMode$1 = testMode !== undefined ? testMode : true;
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var themeObj = match.themeObj;
  React.useEffect((function () {
          if (loader$1) {
            Curry._1(setOpenModal, (function (param) {
                    return false;
                  }));
          } else {
            Curry._1(setOpenModal, (function (param) {
                    return true;
                  }));
          }
        }), [loader$1]);
  var loaderVisibility = loader$1 ? "visible" : "hidden";
  var contentVisibility = React.useMemo((function () {
          if (openModal) {
            return "visible";
          } else {
            return "hidden";
          }
        }), [openModal]);
  var marginTop = testMode$1 ? "mt-8" : "mt-6";
  var animate = contentVisibility === "visible" ? "animate-zoomIn" : "animate-zoomOut";
  var loaderUI = React.createElement("div", {
        className: "flex justify-center m-auto " + loaderVisibility + ""
      }, React.createElement(Loader$OrcaPaymentPage.make, {
            showText: false
          }));
  return React.createElement("div", {
              className: "h-screen w-screen bg-black/40 flex m-auto items-center backdrop-blur-sm overflow-scroll",
              style: {
                opacity: openModal ? "100" : "0",
                transition: "opacity .35s ease .1s,background-color 600ms linear"
              }
            }, loaderUI, React.createElement("div", {
                  className: "w-full h-full sm:h-auto sm:w-[55%] md:w-[45%] lg:w-[35%] xl:w-[32%] 2xl:w-[27%]  m-auto bg-white flex flex-col justify-start sm:justify-center px-5 pb-5 md:pb-6 pt-4 md:pt-7 rounded-none sm:rounded-md relative overflow-scroll " + animate + "",
                  style: {
                    backgroundColor: themeObj.colorBackground === "transparent" ? "" : Utils$OrcaPaymentPage.rgbaTorgb(themeObj.colorBackground),
                    opacity: openModal ? "100" : "0",
                    transition: "opacity .35s ease .1s,transform .35s ease .1s,-webkit-transform .35s ease .1s"
                  }
                }, React.createElement("div", {
                      className: "absolute top-0 left-0 w-full flex flex-col"
                    }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                          condition: testMode$1 && !Window$OrcaPaymentPage.isProd,
                          children: React.createElement("div", {
                                className: "w-full h-6 text-[#885706] bg-[#FDD486] text-xs flex justify-center items-center font-semibold"
                              }, "TEST DATA")
                        }), React.createElement(RenderIf$OrcaPaymentPage.make, {
                          condition: showClose$1,
                          children: React.createElement("div", {
                                className: "p-4 flex justify-end self-end mb-4 cursor-pointer",
                                onClick: (function (param) {
                                    Curry._1(setOpenModal, (function (param) {
                                            return false;
                                          }));
                                    setTimeout((function (param) {
                                            if (closeCallback !== undefined) {
                                              Curry._1(closeCallback, undefined);
                                            }
                                            Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                                                    "fullscreen",
                                                    false
                                                  ]]);
                                          }), 450);
                                  })
                              }, React.createElement(Icon$OrcaPaymentPage.make, {
                                    name: "cross",
                                    size: 23
                                  }))
                        })), React.createElement("div", {
                      className: "mt-12 sm:" + marginTop + ""
                    }, props.children)));
}

var make = Modal;

export {
  close ,
  make ,
}
/* react Not a pure module */
