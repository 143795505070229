// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";

var eventListenerMap = {};

function addSmartEventListener(type_, handlerMethod, activity) {
  var value = Js_dict.get(eventListenerMap, activity);
  if (value !== undefined) {
    window.removeEventListener(type_, value);
  }
  eventListenerMap[activity] = handlerMethod;
  window.addEventListener(type_, handlerMethod);
}

export {
  eventListenerMap ,
  addSmartEventListener ,
}
/* No side effect */
