// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function ErrorOccured(props) {
  return React.createElement("div", {
              className: "flex justify-center font-bold text-lg text-red-600"
            }, "Error occurred");
}

var make = ErrorOccured;

export {
  make ,
}
/* react Not a pure module */
