// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";

function eventNameToStrMapper(eventName) {
  switch (eventName) {
    case /* APP_RENDERED */0 :
        return "APP_RENDERED";
    case /* PAYMENT_METHOD_CHANGED */1 :
        return "PAYMENT_METHOD_CHANGED";
    case /* PAYMENT_DATA_FILLED */2 :
        return "PAYMENT_DATA_FILLED";
    case /* PAYMENT_ATTEMPT */3 :
        return "PAYMENT_ATTEMPT";
    case /* PAYMENT_SUCCESS */4 :
        return "PAYMENT_SUCCESS";
    case /* PAYMENT_FAILED */5 :
        return "PAYMENT_FAILED";
    case /* INPUT_FIELD_CHANGED */6 :
        return "INPUT_FIELD_CHANGED";
    case /* RETRIEVE_CALL_INIT */7 :
        return "RETRIEVE_CALL_INIT";
    case /* RETRIEVE_CALL */8 :
        return "RETRIEVE_CALL";
    case /* CONFIRM_CALL_INIT */9 :
        return "CONFIRM_CALL_INIT";
    case /* CONFIRM_CALL */10 :
        return "CONFIRM_CALL";
    case /* SESSIONS_CALL_INIT */11 :
        return "SESSIONS_CALL_INIT";
    case /* SESSIONS_CALL */12 :
        return "SESSIONS_CALL";
    case /* PAYMENT_METHODS_CALL_INIT */13 :
        return "PAYMENT_METHODS_CALL_INIT";
    case /* PAYMENT_METHODS_CALL */14 :
        return "PAYMENT_METHODS_CALL";
    case /* CUSTOMER_PAYMENT_METHODS_CALL_INIT */15 :
        return "CUSTOMER_PAYMENT_METHODS_CALL_INIT";
    case /* CUSTOMER_PAYMENT_METHODS_CALL */16 :
        return "CUSTOMER_PAYMENT_METHODS_CALL";
    case /* TRUSTPAY_SCRIPT */17 :
        return "TRUSTPAY_SCRIPT";
    case /* GOOGLE_PAY_SCRIPT */18 :
        return "GOOGLE_PAY_SCRIPT";
    case /* APPLE_PAY_FLOW */19 :
        return "APPLE_PAY_FLOW";
    case /* GOOGLE_PAY_FLOW */20 :
        return "GOOGLE_PAY_FLOW";
    case /* PAYPAL_FLOW */21 :
        return "PAYPAL_FLOW";
    case /* PAYPAL_SDK_FLOW */22 :
        return "PAYPAL_SDK_FLOW";
    case /* APP_INITIATED */23 :
        return "APP_INITIATED";
    case /* APP_REINITIATED */24 :
        return "APP_REINITIATED";
    case /* LOADER_CALLED */25 :
        return "LOADER_CALLED";
    case /* ORCA_ELEMENTS_CALLED */26 :
        return "ORCA_ELEMENTS_CALLED";
    case /* PAYMENT_OPTIONS_PROVIDED */27 :
        return "PAYMENT_OPTIONS_PROVIDED";
    case /* BLUR */28 :
        return "BLUR";
    case /* FOCUS */29 :
        return "FOCUS";
    case /* CLEAR */30 :
        return "CLEAR";
    case /* CONFIRM_PAYMENT */31 :
        return "CONFIRM_PAYMENT";
    case /* CONFIRM_CARD_PAYMENT */32 :
        return "CONFIRM_CARD_PAYMENT";
    case /* SDK_CRASH */33 :
        return "SDK_CRASH";
    case /* INVALID_PK */34 :
        return "INVALID_PK";
    case /* DEPRECATED_LOADSTRIPE */35 :
        return "DEPRECATED_LOADSTRIPE";
    case /* REQUIRED_PARAMETER */36 :
        return "REQUIRED_PARAMETER";
    case /* UNKNOWN_KEY */37 :
        return "UNKNOWN_KEY";
    case /* UNKNOWN_VALUE */38 :
        return "UNKNOWN_VALUE";
    case /* TYPE_BOOL_ERROR */39 :
        return "TYPE_BOOL_ERROR";
    case /* TYPE_INT_ERROR */40 :
        return "TYPE_INT_ERROR";
    case /* TYPE_STRING_ERROR */41 :
        return "TYPE_STRING_ERROR";
    case /* INVALID_FORMAT */42 :
        return "INVALID_FORMAT";
    case /* SDK_CONNECTOR_WARNING */43 :
        return "SDK_CONNECTOR_WARNING";
    case /* VALUE_OUT_OF_RANGE */44 :
        return "VALUE_OUT_OF_RANGE";
    case /* HTTP_NOT_ALLOWED */45 :
        return "HTTP_NOT_ALLOWED";
    case /* INTERNAL_API_DOWN */46 :
        return "INTERNAL_API_DOWN";
    case /* REDIRECTING_USER */47 :
        return "REDIRECTING_USER";
    case /* DISPLAY_BANK_TRANSFER_INFO_PAGE */48 :
        return "DISPLAY_BANK_TRANSFER_INFO_PAGE";
    case /* DISPLAY_QR_CODE_INFO_PAGE */49 :
        return "DISPLAY_QR_CODE_INFO_PAGE";
    
  }
}

function convertToScreamingSnakeCase(text) {
  return text.trim().replace(/ /g, "_").toUpperCase();
}

function logInfo(log) {
  if (Window$OrcaPaymentPage.isProd) {
    return ;
  } else {
    return log;
  }
}

function defaultLoggerConfig_setLogInfo(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogError(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogApi(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  
}

function defaultLoggerConfig_setLogInitiated(param) {
  
}

function defaultLoggerConfig_setConfirmPaymentValue(param) {
  return {};
}

function defaultLoggerConfig_sendLogs(param) {
  
}

function defaultLoggerConfig_setSessionId(_x) {
  
}

function defaultLoggerConfig_setClientSecret(_x) {
  
}

function defaultLoggerConfig_setMerchantId(_x) {
  
}

function defaultLoggerConfig_setMetadata(_x) {
  
}

var defaultLoggerConfig = {
  setLogInfo: defaultLoggerConfig_setLogInfo,
  setLogError: defaultLoggerConfig_setLogError,
  setLogApi: defaultLoggerConfig_setLogApi,
  setLogInitiated: defaultLoggerConfig_setLogInitiated,
  setConfirmPaymentValue: defaultLoggerConfig_setConfirmPaymentValue,
  sendLogs: defaultLoggerConfig_sendLogs,
  setSessionId: defaultLoggerConfig_setSessionId,
  setClientSecret: defaultLoggerConfig_setClientSecret,
  setMerchantId: defaultLoggerConfig_setMerchantId,
  setMetadata: defaultLoggerConfig_setMetadata
};

function logFileToObj(logFile) {
  var match = logFile.logType;
  var tmp;
  switch (match) {
    case /* DEBUG */0 :
        tmp = "DEBUG";
        break;
    case /* INFO */1 :
        tmp = "INFO";
        break;
    case /* ERROR */2 :
        tmp = "ERROR";
        break;
    case /* WARNING */3 :
        tmp = "WARNING";
        break;
    case /* SILENT */4 :
        tmp = "SILENT";
        break;
    
  }
  var match$1 = logFile.category;
  var tmp$1;
  switch (match$1) {
    case /* API */0 :
        tmp$1 = "API";
        break;
    case /* USER_ERROR */1 :
        tmp$1 = "USER_ERROR";
        break;
    case /* USER_EVENT */2 :
        tmp$1 = "USER_EVENT";
        break;
    case /* MERCHANT_EVENT */3 :
        tmp$1 = "MERCHANT_EVENT";
        break;
    
  }
  return Js_dict.fromArray([
              [
                "timestamp",
                logFile.timestamp
              ],
              [
                "log_type",
                tmp
              ],
              [
                "component",
                "WEB"
              ],
              [
                "category",
                tmp$1
              ],
              [
                "source",
                convertToScreamingSnakeCase(logFile.source)
              ],
              [
                "version",
                logFile.version
              ],
              [
                "value",
                logFile.value
              ],
              [
                "internal_metadata",
                logFile.internalMetadata
              ],
              [
                "session_id",
                logFile.sessionId
              ],
              [
                "merchant_id",
                logFile.merchantId
              ],
              [
                "payment_id",
                logFile.paymentId
              ],
              [
                "app_id",
                logFile.appId
              ],
              [
                "platform",
                convertToScreamingSnakeCase(logFile.platform)
              ],
              [
                "user_agent",
                logFile.userAgent
              ],
              [
                "event_name",
                logFile.eventName
              ],
              [
                "browser_name",
                convertToScreamingSnakeCase(logFile.browserName)
              ],
              [
                "browser_version",
                logFile.browserVersion
              ],
              [
                "latency",
                logFile.latency
              ],
              [
                "first_event",
                logFile.firstEvent ? "true" : "false"
              ],
              [
                "payment_method",
                convertToScreamingSnakeCase(logFile.paymentMethod)
              ]
            ]);
}

function getRefFromOption(val) {
  var innerValue = Belt_Option.getWithDefault(val, "");
  return {
          contents: innerValue
        };
}

function getSourceString(source) {
  if (source) {
    return "orca-element";
  } else {
    return "orca-loader";
  }
}

function findVersion(re, content) {
  var result = re.exec(content);
  if (result !== null) {
    return result;
  } else {
    return [];
  }
}

function browserDetect(content) {
  if (new RegExp("Edg").test(content)) {
    var re = /Edg\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re, content), 1), null);
    var version = (a == null) ? "" : a;
    return "Microsoft Edge-" + version + "";
  }
  if (new RegExp("Chrome").test(content)) {
    var re$1 = /Chrome\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$1 = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re$1, content), 1), null);
    var version$1 = (a$1 == null) ? "" : a$1;
    return "Chrome-" + version$1 + "";
  }
  if (new RegExp("Safari").test(content)) {
    var re$2 = /Safari\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$2 = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re$2, content), 1), null);
    var version$2 = (a$2 == null) ? "" : a$2;
    return "Safari-" + version$2 + "";
  }
  if (new RegExp("opera").test(content)) {
    var re$3 = /Opera\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$3 = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re$3, content), 1), null);
    var version$3 = (a$3 == null) ? "" : a$3;
    return "Opera-" + version$3 + "";
  }
  if (!(new RegExp("Firefox").test(content) || new RegExp("fxios").test(content))) {
    return "Others-0";
  }
  if (new RegExp("Firefox").test(content)) {
    var re$4 = /Firefox\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$4 = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re$4, content), 1), null);
    var version$4 = (a$4 == null) ? "" : a$4;
    return "Firefox-" + version$4 + "";
  }
  var re$5 = /fxios\/([\d]+\.[\w]?\.?[\w]+)/ig;
  var a$5 = Belt_Option.getWithDefault(Belt_Array.get(findVersion(re$5, content), 1), null);
  var version$5 = (a$5 == null) ? "" : a$5;
  return "Firefox-" + version$5 + "";
}

var arrayOfNameAndVersion = browserDetect(navigator.userAgent).split("-");

function make(sessionId, source, clientSecret, merchantId, metadata, param) {
  var match = loggingLevel;
  var loggingLevel$1;
  switch (match) {
    case "DEBUG" :
        loggingLevel$1 = /* DEBUG */0;
        break;
    case "ERROR" :
        loggingLevel$1 = /* ERROR */2;
        break;
    case "INFO" :
        loggingLevel$1 = /* INFO */1;
        break;
    case "SILENT" :
        loggingLevel$1 = /* SILENT */4;
        break;
    case "WARNING" :
        loggingLevel$1 = /* WARNING */3;
        break;
    default:
      loggingLevel$1 = /* SILENT */4;
  }
  var mainLogFile = [];
  var sessionId$1 = getRefFromOption(sessionId);
  var setSessionId = function (value) {
    sessionId$1.contents = value;
  };
  var sourceString = source !== undefined ? (
      source ? "orca-element" : "orca-loader"
    ) : repoName;
  var timeOut = {
    contents: undefined
  };
  var merchantId$1 = getRefFromOption(merchantId);
  var setMerchantId = function (value) {
    merchantId$1.contents = value;
  };
  var metadata$1 = {
    contents: Belt_Option.getWithDefault(metadata, null)
  };
  var setMetadata = function (value) {
    metadata$1.contents = value;
  };
  var conditionalLogPush = function (log) {
    if (!enableLogging) {
      return ;
    }
    switch (loggingLevel$1) {
      case /* DEBUG */0 :
          mainLogFile.push(log);
          return ;
      case /* INFO */1 :
          if ([
                /* INFO */1,
                /* WARNING */3,
                /* ERROR */2
              ].includes(log.logType)) {
            mainLogFile.push(log);
            return ;
          } else {
            return ;
          }
      case /* ERROR */2 :
          if ([/* ERROR */2].includes(log.logType)) {
            mainLogFile.push(log);
            return ;
          } else {
            return ;
          }
      case /* WARNING */3 :
          if ([
                /* WARNING */3,
                /* ERROR */2
              ].includes(log.logType)) {
            mainLogFile.push(log);
            return ;
          } else {
            return ;
          }
      case /* SILENT */4 :
          return ;
      
    }
  };
  var beaconApiCall = function (data) {
    if (data.length <= 0) {
      return ;
    }
    var logData = JSON.stringify(Js_dict.fromArray([[
                "data",
                data.map(logFileToObj)
              ]]));
    navigator.sendBeacon(logEndpoint, logData);
  };
  var clientSecret$1 = getRefFromOption(clientSecret);
  var setClientSecret = function (value) {
    clientSecret$1.contents = value;
  };
  var events = {
    contents: {}
  };
  var sendLogs = function (param) {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function (param) {
                  sendLogs(undefined);
                }), 120000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function (param) {
                  sendLogs(undefined);
                }), 120000));
    }
    beaconApiCall(mainLogFile);
    var len = mainLogFile.length;
    for(var _for = 0; _for < len; ++_for){
      mainLogFile.pop();
    }
  };
  var checkForPriorityEvents = function (arrayOfLogs) {
    var priorityEventNames = [
      "APP_RENDERED",
      "ORCA_ELEMENTS_CALLED",
      "PAYMENT_DATA_FILLED",
      "PAYMENT_ATTEMPT",
      "CONFIRM_CALL",
      "SDK_CRASH",
      "REDIRECTING_USER",
      "DISPLAY_BANK_TRANSFER_INFO_PAGE",
      "DISPLAY_QR_CODE_INFO_PAGE",
      "SESSIONS_CALL"
    ];
    if (Belt_Option.isSome(Caml_option.undefined_to_opt(arrayOfLogs.find(function (log) {
                    return [
                              /* ERROR */2,
                              /* DEBUG */0
                            ].includes(log.logType) ? true : (
                              priorityEventNames.includes(log.eventName) ? log.firstEvent : false
                            );
                  })))) {
      return true;
    } else {
      return arrayOfLogs.length > 8;
    }
  };
  var checkLogSizeAndSendData = function (param) {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function (param) {
                  sendLogs(undefined);
                }), 20000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function (param) {
                  sendLogs(undefined);
                }), 20000));
    }
    if (checkForPriorityEvents(mainLogFile)) {
      return sendLogs(undefined);
    }
    
  };
  var calculateLatencyHook = function (eventName, type_Opt, param) {
    var type_ = type_Opt !== undefined ? type_Opt : "";
    var currentTimestamp = Date.now();
    var latency;
    var exit = 0;
    switch (eventName) {
      case "PAYMENT_ATTEMPT" :
          var appRenderedTimestamp = Js_dict.get(events.contents, "APP_RENDERED");
          latency = appRenderedTimestamp !== undefined ? currentTimestamp - appRenderedTimestamp : -1;
          break;
      case "CONFIRM_CALL" :
      case "CUSTOMER_PAYMENT_METHODS_CALL" :
      case "PAYMENT_METHODS_CALL" :
      case "RETRIEVE_CALL" :
      case "SESSIONS_CALL" :
          exit = 1;
          break;
      default:
        latency = 0;
    }
    if (exit === 1) {
      var logRequestTimestamp = Js_dict.get(events.contents, eventName + "_INIT");
      latency = logRequestTimestamp !== undefined ? (
          type_ === "request" ? 0 : currentTimestamp - logRequestTimestamp
        ) : 0;
    }
    if (latency > 0) {
      return String(latency);
    } else {
      return "";
    }
  };
  var setLogInfo = function (value, internalMetadataOpt, eventName, timestamp, logTypeOpt, logCategoryOpt, paymentMethodOpt, param) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : /* INFO */1;
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : /* USER_EVENT */2;
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Belt_Option.isNone(Js_dict.get(events.contents, eventNameStr));
    var latency = calculateLatencyHook(eventNameStr, undefined, undefined);
    var localTimestamp = Belt_Option.getWithDefault(timestamp, String(Date.now()));
    var localTimestampFloat = Belt_Option.getWithDefault(Belt_Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: Belt_Option.getWithDefault(Belt_Array.get(clientSecret$1.contents.split("_secret_"), 0), ""),
          appId: "",
          platform: navigator.platform,
          browserName: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 0), "Others"),
          browserVersion: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 1), "0"),
          userAgent: navigator.userAgent,
          eventName: eventNameStr,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents
        });
    checkLogSizeAndSendData(undefined);
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setConfirmPaymentValue = function (paymentType) {
    return Js_dict.fromArray([
                [
                  "method",
                  "confirmPayment"
                ],
                [
                  "type",
                  paymentType
                ]
              ]);
  };
  var setLogApi = function (value, internalMetadata, eventName, timestamp, logTypeOpt, logCategoryOpt, paymentMethodOpt, type_Opt, param) {
    var logType = logTypeOpt !== undefined ? logTypeOpt : /* INFO */1;
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : /* API */0;
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var type_ = type_Opt !== undefined ? type_Opt : "";
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Belt_Option.isNone(Js_dict.get(events.contents, eventNameStr));
    var latency = calculateLatencyHook(eventNameStr, type_, undefined);
    var localTimestamp = Belt_Option.getWithDefault(timestamp, String(Date.now()));
    var localTimestampFloat = Belt_Option.getWithDefault(Belt_Float.fromString(localTimestamp), Date.now());
    var tmp;
    tmp = value.TAG === /* ArrayType */0 ? JSON.stringify(Js_dict.fromArray(value._0)) : value._0;
    var tmp$1;
    tmp$1 = internalMetadata.TAG === /* ArrayType */0 ? JSON.stringify(Js_dict.fromArray(internalMetadata._0)) : internalMetadata._0;
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: tmp,
          internalMetadata: tmp$1,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: Belt_Option.getWithDefault(Belt_Array.get(clientSecret$1.contents.split("_secret_"), 0), ""),
          appId: "",
          platform: navigator.platform,
          browserName: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 0), "Others"),
          browserVersion: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 1), "0"),
          userAgent: navigator.userAgent,
          eventName: eventNameStr,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents
        });
    checkLogSizeAndSendData(undefined);
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogError = function (value, internalMetadataOpt, eventName, timestamp, logTypeOpt, logCategoryOpt, paymentMethodOpt, param) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : /* ERROR */2;
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : /* USER_ERROR */1;
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Belt_Option.isNone(Js_dict.get(events.contents, eventNameStr));
    var latency = calculateLatencyHook(eventNameStr, undefined, undefined);
    var localTimestamp = Belt_Option.getWithDefault(timestamp, String(Date.now()));
    var localTimestampFloat = Belt_Option.getWithDefault(Belt_Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: Belt_Option.getWithDefault(Belt_Array.get(clientSecret$1.contents.split("_secret_"), 0), ""),
          appId: "",
          platform: navigator.platform,
          browserName: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 0), "Others"),
          browserVersion: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 1), "0"),
          userAgent: navigator.userAgent,
          eventName: eventNameStr,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents
        });
    checkLogSizeAndSendData(undefined);
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogInitiated = function (param) {
    var eventName = "LOG_INITIATED";
    var firstEvent = Belt_Option.isNone(Js_dict.get(events.contents, eventName));
    var latency = calculateLatencyHook(eventName, undefined, undefined);
    conditionalLogPush({
          timestamp: String(Date.now()),
          logType: /* INFO */1,
          category: /* USER_EVENT */2,
          source: sourceString,
          version: repoVersion,
          value: "log initiated",
          internalMetadata: "",
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: Belt_Option.getWithDefault(Belt_Array.get(clientSecret$1.contents.split("_secret_"), 0), ""),
          appId: "",
          platform: navigator.platform,
          browserName: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 0), "Others"),
          browserVersion: Belt_Option.getWithDefault(Belt_Array.get(arrayOfNameAndVersion, 1), "0"),
          userAgent: navigator.userAgent,
          eventName: eventName,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: "",
          metadata: metadata$1.contents
        });
    checkLogSizeAndSendData(undefined);
    events.contents[eventName] = Date.now();
  };
  var handleBeforeUnload = function (_event) {
    sendLogs(undefined);
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      return ;
    }
    
  };
  window.addEventListener("beforeunload", handleBeforeUnload);
  return {
          setLogInfo: setLogInfo,
          setLogError: setLogError,
          setLogApi: setLogApi,
          setLogInitiated: setLogInitiated,
          setConfirmPaymentValue: setConfirmPaymentValue,
          sendLogs: sendLogs,
          setSessionId: setSessionId,
          setClientSecret: setClientSecret,
          setMerchantId: setMerchantId,
          setMetadata: setMetadata
        };
}

export {
  eventNameToStrMapper ,
  convertToScreamingSnakeCase ,
  logInfo ,
  defaultLoggerConfig ,
  logFileToObj ,
  getRefFromOption ,
  getSourceString ,
  findVersion ,
  browserDetect ,
  arrayOfNameAndVersion ,
  make ,
}
/* arrayOfNameAndVersion Not a pure module */
