// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxPPXReactSupport from "rescript/lib/es6/jsxPPXReactSupport.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";

function Loader(props) {
  var showText = props.showText;
  var showText$1 = showText !== undefined ? showText : true;
  var arr = [
    "hyperswitch-triangle",
    "hyperswitch-square",
    "hyperswitch-circle"
  ];
  return React.createElement("div", {
              className: "flex flex-col gap-10 justify-center items-center"
            }, React.createElement("div", {
                  className: "flex flex-row gap-10"
                }, arr.map(function (item, i) {
                      return JsxPPXReactSupport.createElementWithKey(String(i), Icon$OrcaPaymentPage.make, {
                                  name: item,
                                  size: 52,
                                  style: {
                                    animation: "slowShow 1.5s ease-in-out infinite",
                                    animationDelay: String(Math.imul(i + 1 | 0, 180)) + "ms"
                                  }
                                });
                    })), React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: showText$1,
                  children: React.createElement("div", {
                        className: "flex flex-col gap-5"
                      }, React.createElement("div", {
                            className: "font-semibold text-sm text-gray-200 self-center "
                          }, "We are processing your payment..."), React.createElement("div", {
                            className: "font-medium text-xs text-gray-400 self-center text-center w-3/4 "
                          }, "You have been redirected to new tab to complete your payments. Status will be updated automatically"))
                }));
}

var make = Loader;

export {
  make ,
}
/* react Not a pure module */
