// Generated by ReScript, PLEASE EDIT WITH CARE

import PackageJson from "/package.json";
import * as GlobalVars$OrcaPaymentPage from "./GlobalVars.bs.js";

function iframePostMessage(iframeRef, message) {
  if (iframeRef == null) {
    console.error("This element does not exist or is not mounted yet.");
    return ;
  }
  try {
    iframeRef.contentWindow.postMessage(JSON.stringify(message), GlobalVars$OrcaPaymentPage.targetOrigin);
    return ;
  }
  catch (exn){
    return ;
  }
}

var version = PackageJson.version;

var isSandbox = window.location.hostname === "beta.hyperswitch.io";

var isInteg = window.location.hostname === "dev.hyperswitch.io";

var isProd = window.location.hostname === "checkout.hyperswitch.io";

export {
  iframePostMessage ,
  version ,
  isSandbox ,
  isInteg ,
  isProd ,
}
/* version Not a pure module */
