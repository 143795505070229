// Generated by ReScript, PLEASE EDIT WITH CARE


function PreMountLoader(props) {
  return null;
}

var make = PreMountLoader;

export {
  make ,
}
/* No side effect */
