// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";

function safeParseOpt(st) {
  try {
    return Caml_option.some(JSON.parse(st));
  }
  catch (_e){
    return ;
  }
}

function safeParse(st) {
  return Belt_Option.getWithDefault(safeParseOpt(st), null);
}

function flattenObject(obj, addIndicatorForObject) {
  var newDict = {};
  var obj$1 = Js_json.decodeObject(obj);
  if (obj$1 !== undefined) {
    Js_dict.entries(Caml_option.valFromOption(obj$1)).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var _valueObj = Js_json.decodeObject(value);
          if (_valueObj !== undefined) {
            if (addIndicatorForObject) {
              newDict[key] = {};
            }
            var flattenedSubObj = flattenObject(value, addIndicatorForObject);
            Js_dict.entries(flattenedSubObj).forEach(function (subEntry) {
                  newDict["" + key + "." + subEntry[0] + ""] = subEntry[1];
                });
            return ;
          }
          newDict[key] = value;
        });
  }
  return newDict;
}

function flattenObjectWithStringifiedJson(obj, addIndicatorForObject, keepParent) {
  var newDict = {};
  var obj$1 = Js_json.decodeObject(obj);
  if (obj$1 !== undefined) {
    Js_dict.entries(Caml_option.valFromOption(obj$1)).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var st = Belt_Option.getWithDefault(Js_json.decodeString(value), "");
          var _valueObj = Js_json.decodeObject(Belt_Option.getWithDefault(safeParseOpt(st), null));
          if (_valueObj !== undefined) {
            if (addIndicatorForObject) {
              newDict[key] = {};
            }
            var st$1 = Belt_Option.getWithDefault(Js_json.decodeString(value), "");
            var flattenedSubObj = flattenObjectWithStringifiedJson(Belt_Option.getWithDefault(safeParseOpt(st$1), null), addIndicatorForObject, keepParent);
            Js_dict.entries(flattenedSubObj).forEach(function (subEntry) {
                  var subKey = subEntry[0];
                  var keyN = keepParent ? "" + key + "." + subKey + "" : subKey;
                  newDict[keyN] = subEntry[1];
                });
            return ;
          }
          newDict[key] = value;
        });
  }
  return newDict;
}

function flatten(obj, addIndicatorForObject) {
  var newDict = {};
  var obj$1 = Js_json.classify(obj);
  if (typeof obj$1 !== "number" && obj$1.TAG === /* JSONObject */2) {
    Js_dict.entries(obj$1._0).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var _valueObjDict = Js_json.classify(value);
          if (typeof _valueObjDict === "number") {
            newDict[key] = value;
            return ;
          }
          switch (_valueObjDict.TAG | 0) {
            case /* JSONObject */2 :
                if (addIndicatorForObject) {
                  newDict[key] = {};
                }
                var flattenedSubObj = flatten(value, addIndicatorForObject);
                Js_dict.entries(flattenedSubObj).forEach(function (subEntry) {
                      newDict["" + key + "." + subEntry[0] + ""] = subEntry[1];
                    });
                return ;
            case /* JSONArray */3 :
                var stringArray = [];
                var arrayArray = [];
                _valueObjDict._0.forEach(function (item, index) {
                      var _str = Js_json.classify(item);
                      if (typeof _str === "number") {
                        arrayArray.push(item);
                        return ;
                      }
                      switch (_str.TAG | 0) {
                        case /* JSONString */0 :
                            stringArray.push(item);
                            return ;
                        case /* JSONObject */2 :
                            var flattenedSubObj = flatten(item, addIndicatorForObject);
                            Js_dict.entries(flattenedSubObj).forEach(function (subEntry) {
                                  newDict["" + key + "[" + String(index) + "]." + subEntry[0] + ""] = subEntry[1];
                                });
                            return ;
                        default:
                          arrayArray.push(item);
                          return ;
                      }
                    });
                if (stringArray.length > 0) {
                  newDict[key] = stringArray;
                }
                if (arrayArray.length > 0) {
                  newDict[key] = arrayArray;
                  return ;
                } else {
                  return ;
                }
            default:
              newDict[key] = value;
              return ;
          }
        });
  }
  return newDict;
}

function setNested(_dict, _keys, value) {
  while(true) {
    var keys = _keys;
    var dict = _dict;
    if (keys.length === 0) {
      return ;
    }
    if (keys.length === 1) {
      dict[Caml_array.get(keys, 0)] = value;
      return ;
    }
    var key = Caml_array.get(keys, 0);
    var json = Js_dict.get(dict, key);
    var subDict;
    if (json !== undefined) {
      var obj = Js_json.decodeObject(Caml_option.valFromOption(json));
      subDict = obj !== undefined ? Caml_option.valFromOption(obj) : dict;
    } else {
      var subDict$1 = {};
      dict[key] = subDict$1;
      subDict = subDict$1;
    }
    var remainingKeys = keys.slice(1);
    _keys = remainingKeys;
    _dict = subDict;
    continue ;
  };
}

function unflattenObject(obj) {
  var newDict = {};
  var dict = Js_json.decodeObject(obj);
  if (dict !== undefined) {
    Js_dict.entries(Caml_option.valFromOption(dict)).forEach(function (entry) {
          setNested(newDict, entry[0].split("."), entry[1]);
        });
  }
  return newDict;
}

function getEventDataObj(ev) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_json.decodeObject(ev), (function (x) {
                    return Js_dict.get(x, "data");
                  })), {});
}

function getStrValueFromEventDataObj(ev, key) {
  var obj = getEventDataObj(ev);
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(obj), (function (x) {
                        return Js_dict.get(x, key);
                      })), Js_json.decodeString), "");
}

function getBoolValueFromEventDataObj(ev, key) {
  var obj = getEventDataObj(ev);
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(obj), (function (x) {
                        return Js_dict.get(x, key);
                      })), Js_json.decodeBoolean), false);
}

function getClasses(options, key) {
  var classes = Utils$OrcaPaymentPage.getDictFromObj(options, "classes");
  return Utils$OrcaPaymentPage.getString(classes, key, "");
}

function eventHandlerFunc(condition, eventHandler, evType, activity) {
  var changeHandler = function (ev) {
    if (Curry._1(condition, ev) && !(evType > 6 || evType < 1 || eventHandler === undefined)) {
      return Curry._1(eventHandler, ev.data);
    }
    
  };
  EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", changeHandler, activity);
}

function makeIframe(element, url) {
  return new Promise((function (resolve, param) {
                var iframe = document.createElement("iframe");
                iframe.id = "orca-fullscreen";
                iframe.src = url;
                iframe.name = "fullscreen";
                iframe.style = "position: fixed; inset: 0; width: 100vw; height: 100vh; border: 0; z-index: 422222133323; ";
                iframe.onload = (function (param) {
                    resolve({});
                  });
                element.appendChild(iframe);
              }));
}

function getOptionalJson(ev, str) {
  return Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(getEventDataObj(ev)), {}), str);
}

function getOptionalJsonFromJson(ev, str) {
  return Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(ev), {}), str);
}

function getStringfromOptionaljson(json, $$default) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(json, Js_json.decodeString), $$default);
}

function getBoolfromjson(json, $$default) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(json, Js_json.decodeBoolean), $$default);
}

function getFloatfromjson(json, $$default) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(json, Js_json.decodeNumber), $$default);
}

function getStringfromjson(json, $$default) {
  return Belt_Option.getWithDefault(Js_json.decodeString(json), $$default);
}

function getThemePromise(dict) {
  var theme = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "appearance")), "theme", "default");
  switch (theme) {
    case "brutal" :
        return Caml_option.some(import("../BrutalTheme.bs.js"));
    case "charcoal" :
        return Caml_option.some(import("../CharcoalTheme.bs.js"));
    case "default" :
        return ;
    case "midnight" :
        return Caml_option.some(import("../MidnightTheme.bs.js"));
    case "none" :
        return Caml_option.some(import("../NoTheme.bs.js"));
    case "soft" :
        return Caml_option.some(import("../SoftTheme.bs.js"));
    default:
      return ;
  }
}

function mergeTwoFlattenedJsonDicts(dict1, dict2) {
  return unflattenObject(Js_dict.fromArray(Js_dict.entries(dict1).concat(Js_dict.entries(dict2))));
}

function getArrayOfTupleFromDict(dict) {
  return Belt_Array.map(Object.keys(dict), (function (key) {
                return [
                        key,
                        Belt_Option.getWithDefault(Js_dict.get(dict, key), null)
                      ];
              }));
}

export {
  safeParseOpt ,
  safeParse ,
  flattenObject ,
  flattenObjectWithStringifiedJson ,
  flatten ,
  setNested ,
  unflattenObject ,
  getEventDataObj ,
  getStrValueFromEventDataObj ,
  getBoolValueFromEventDataObj ,
  getClasses ,
  eventHandlerFunc ,
  makeIframe ,
  getOptionalJson ,
  getOptionalJsonFromJson ,
  getStringfromOptionaljson ,
  getBoolfromjson ,
  getFloatfromjson ,
  getStringfromjson ,
  getThemePromise ,
  mergeTwoFlattenedJsonDicts ,
  getArrayOfTupleFromDict ,
}
/* Utils-OrcaPaymentPage Not a pure module */
