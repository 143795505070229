// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function CardElementShimmer(props) {
  return React.createElement("div", {
              className: "shine w-full h-12 rounded"
            }, React.createElement("div", {
                  className: "flex flex-row  my-auto"
                }, React.createElement("div", {
                      className: " w-10 h-5 rounded-full m-3 bg-white bg-opacity-70 "
                    }), React.createElement("div", {
                      className: " my-auto w-24 h-2 rounded m-3 bg-white bg-opacity-70 "
                    })));
}

var make = CardElementShimmer;

export {
  make ,
}
/* react Not a pure module */
